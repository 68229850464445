<template>
    <div>
        <div
            v-if="mode === 'list'"
        >
            <v-card-title
                style="background-color: #5782c2;"
                class="d-flex align-center justify-space-between pa-2"
            >
                <span style="color: white">Vertretung auswählen</span>
                <v-btn
                    x-small
                    text
                    elevation="0"
                    class="pa-0"
                    @click="closeDialog"
                >
                    <img
                        :src="closeIcon"
                        class="icon20 iconToWhite"
                        alt="schließen"
                    >
                </v-btn>
            </v-card-title>
            <div>
                <div
                    v-if="appointment"
                    class="d-flex flex-column px-2"
                >
                    <div class="d-flex flex-row justify-end align-center py-2">
                        <v-btn
                            color="error"
                            @click="clickNoSubstitution"
                        >
                            Ausfall melden
                        </v-btn>
                        <v-tooltip
                            top
                            max-width="400px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <img
                                    :src="infoIcon"
                                    class="ml-2; "
                                    style="height: 30px"
                                    alt="Erklärung"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                            </template>
                            <span>Wenn Sie auf "Ausfall melden" drücken, wird kein Vertretungslehrer ausgewählt und die Stunde als Entfall gekennzeichnet.
                                Sie können im nachhinein trotzdem noch Vertretungen hinzufügen um dies Rückgängig zu machen.
                            </span>
                        </v-tooltip>
                    </div>
                    <v-text-field
                        v-if="!selectedFilters.includes('Standort')"
                        v-model="searchTeacher"
                        label="Lehrer suchen"
                        single-line
                        dense
                        hide-details
                        class="mb-1 mr-4"
                        :append-icon="searchTeacher.trim().length > 0 ? 'mdi-close' : ''"
                        @click:append="searchTeacher = ''"
                    >
                        <template v-slot:prepend-inner>
                            <img
                                :src="magnifierIcon"
                                class="icon20 iconToDunkelgrau"
                                alt="Suche"
                            >
                        </template>
                    </v-text-field>
                    <v-text-field
                        v-if="selectedFilters.includes('Standort')"
                        v-model="searchLocation"
                        label="Standort suchen"
                        single-line
                        dense
                        hide-details
                        class="mb-1 mr-4"
                        :append-icon="searchLocation.trim().length > 0 ? 'mdi-close' : ''"
                        @click:append="searchLocation = ''"
                    >
                        <template v-slot:prepend-inner>
                            <img
                                :src="magnifierIcon"
                                class="icon20 iconToDunkelgrau"
                                alt="Suche"
                            >
                        </template>
                    </v-text-field>
                    <div class="d-flex flex-row justify-space-between align-center">
                        <h1 style="font-size: 18px;color:green;">
                            Verfügbare Lehrer     
                        </h1>
                        <v-combobox
                            v-model="selectedFilters"
                            :items="possibleFilters"
                            chips
                            clearable
                            label="Filter"
                            multiple
                            prepend-icon="mdi-filter"
                            solo
                            flat
                            class="py-1"
                            style="max-width: 160px;"
                            hide-details
                        >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                                <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    close
                                    style="background-color: var(--v-timetableColor-base)"
                                    text-color="white"
                                    @click="select"
                                    @click:close="remove(item)"
                                >
                                    <strong>{{ item }}</strong>
                                </v-chip>
                            </template>
                        </v-combobox>
                    </div>
                </div>
                <div
                    class="d-flex flex-column"
                    style="flex:auto; background-color:white; align-items:center; max-width: 400px"
                >
                    <div 
                        v-if="showLoadingOverlay"
                        class="d-flex flex-column align-center"
                    >
                        <v-progress-circular
                            v-if="showLoadingOverlay"
                            indeterminate
                            size="40"
                            color="var(--v-timetableColor-base)"
                        />
                        Verfügbare Lehrer werden geladen
                    </div>
                    <vue-custom-scrollbar
                        v-if="!showLoadingOverlay"
                        class="available-scroll-area"
                    >
                        <div v-if="!this.selectedFilters.includes('Standort')">
                            <div
                                v-for="(teacher, index) in searchedAvailableTeachers"
                                :key="index"
                                class="teacherRow"
                                style="width: 100%; border-bottom: solid; border-color: rgba(0, 0, 0, 0.12); border-width: 1px; background-color: #fff"
                            >
                                <div
                                    class="d-flex my-1"
                                    style="align-items: center; width: 100%"
                                >
                                    <div style="width: 75%; display: flex; ">
                                        <div style="width: 22%">
                                            <v-badge
                                                :color="calculateColor(teacher.workHours,teacher.maxHours)"
                                                :content="`${Math.round(teacher.workHours)}/${teacher.maxHours}`"
                                                left
                                                class="ml-5"
                                                overlap
                                                offset-y="12"
                                                offset-x="30"
                                            >
                                                <Avatar
                                                    :profile="teacher.account"
                                                    :size="36"
                                                    :show-burger="false"
                                                />
                                            </v-badge>
                                        </div>
                                        <div
                                            style="width: 78%"
                                            class="ml-2"
                                        >
                                            <div
                                                class="d-flex "
                                                style="width: 100%; flex-flow: wrap; justify-content: start"
                                            >
                                                <p
                                                    class="mr-1"
                                                    style="font-size: 14px; max-width: 120px; overflow: hidden;text-overflow: ellipsis; width: fit-content;"
                                                >
                                                    {{ teacher.name[0] + '. ' + teacher.lastName }}
                                                </p>
                                                <div class="d-flex flex-row">
                                                    <div
                                                        v-for="(subject,index) in teacher.schoolSubjects.slice(0,3)"
                                                        :key="index"
                                                        style="padding: 0 1px; "
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <img
                                                                    :src="getIcon(subject)"
                                                                    class="subjectIconStyle"
                                                                    :class="!subject.uploadedIcon ? 'pa-1' : ''"
                                                                    :style="`background-color: ${subject.uploadedIcon ? '' : subject.color}`"
                                                                    v-on="on"
                                                                >
                                                            </template>
                                                            <span>{{ subject.name }}</span>
                                                        </v-tooltip>
                                                    </div>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <span
                                                                v-if="teacher.schoolSubjects.length > 3"
                                                                v-on="on"
                                                            > ... </span>
                                                        </template>
                                                        <div style="max-width:200px;">
                                                            Alle Fächer:
                                                            <span
                                                                v-for="(subject,index) in teacher.schoolSubjects"
                                                                :key="index"
                                                                style="padding: 0 1px; "
                                                            >
                                                                {{ subject.name }},
                                                            </span>
                                                        </div>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                            <div
                                                v-if="teacher.substitutionNote"
                                                class="d-flex flex-row align-center"
                                                style="margin-left: -14px;"
                                            >
                                                <img
                                                    :src="infoIcon"
                                                    class="icon20 mr-1"
                                                    alt="Notiz"
                                                >
                                                <p
                                                    style="font-size: 12px; overflow-wrap: anywhere;"
                                                >
                                                    {{ teacher.substitutionNote }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width: 25%">
                                        <v-tooltip
                                            v-if="appointment"
                                            bottom
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class=" pa-0"
                                                    small
                                                    style="background-color: var(--v-success-base)"
                                                    min-width="36px"
                                                    v-on="on"
                                                    @click="$emit('handleSubstituteClick', teacher)"
                                                >
                                                    <span
                                                        style="color: white"
                                                        class="pl-2 pr-2"
                                                    >
                                                        Wählen
                                                    </span>
                                                </v-btn>
                                            </template>
                                            <span>
                                                Lehrer*in zuweisen
                                            </span>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="this.selectedFilters.includes('Standort') && this.appointment">
                            <div
                                v-for="(location, index1) in filteredTeachersByLocation"
                                :key="index1"
                            >
                                <h1 class="ml-2 mb-2">
                                    {{ location.locationName }}
                                </h1>
                                <div
                                    v-for="(teacher, index2) in location.teachers"
                                    :key="index2"
                                    class="teacherRow"
                                    style="width: 100%; border-bottom: solid; border-color: rgba(0, 0, 0, 0.12); border-width: 1px; background-color: #fff"
                                >
                                    <div
                                        class="d-flex my-1"
                                        style="align-items: center; width: 100%"
                                    >
                                        <div style="width: 75%; display: flex; ">
                                            <div style="width: 22%">
                                                <v-badge
                                                    :color="calculateColor(teacher.workHours,teacher.maxHours)"
                                                    :content="`${Math.round(teacher.workHours)}/${teacher.maxHours}`"
                                                    left
                                                    class="ml-5"
                                                    overlap
                                                    offset-y="12"
                                                    offset-x="30"
                                                >
                                                    <Avatar
                                                        :profile="teacher.account"
                                                        :size="36"
                                                        :show-burger="false"
                                                    />
                                                </v-badge>
                                            </div>
                                            <div
                                                style="width: 78%"
                                                class="ml-2"
                                            >
                                                <div
                                                    class="d-flex "
                                                    style="width: 100%; flex-flow: wrap; justify-content: start"
                                                >
                                                    <p
                                                        class="mr-1"
                                                        style="font-size: 14px; max-width: 120px; overflow: hidden;text-overflow: ellipsis; width: fit-content;"
                                                    >
                                                        {{ teacher.name[0] + '. ' + teacher.lastName }}
                                                    </p>
                                                    <div class="d-flex flex-row">
                                                        <div
                                                            v-for="(subject,index) in teacher.schoolSubjects.slice(0,3)"
                                                            :key="index"
                                                            style="padding: 0 1px; "
                                                        >
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <img
                                                                        :src="getIcon(subject)"
                                                                        class="subjectIconStyle"
                                                                        :class="!subject.uploadedIcon ? 'pa-1' : ''"
                                                                        :style="`background-color: ${subject.uploadedIcon ? '' : subject.color}`"
                                                                        v-on="on"
                                                                    >
                                                                </template>
                                                                <span>{{ subject.name }}</span>
                                                            </v-tooltip>
                                                        </div>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <span
                                                                    v-if="teacher.schoolSubjects.length > 3"
                                                                    v-on="on"
                                                                > ... </span>
                                                            </template>
                                                            <div style="max-width:200px;">
                                                                Alle Fächer:
                                                                <span
                                                                    v-for="(subject,index) in teacher.schoolSubjects"
                                                                    :key="index"
                                                                    style="padding: 0 1px; "
                                                                >
                                                                    {{ subject.name }},
                                                                </span>
                                                            </div>
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="teacher.substitutionNote"
                                                    class="d-flex flex-row align-center"
                                                    style="margin-left: -14px;"
                                                >
                                                    <img
                                                        :src="infoIcon"
                                                        class="icon20 mr-1"
                                                        alt="Notiz"
                                                    >
                                                    <p
                                                        style="font-size: 12px; overflow-wrap: anywhere;"
                                                    >
                                                        {{ teacher.substitutionNote }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width: 25%">
                                            <v-tooltip
                                                v-if="appointment"
                                                bottom
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        class=" pa-0"
                                                        small
                                                        style="background-color: var(--v-success-base)"
                                                        min-width="36px"
                                                        v-on="on"
                                                        @click="$emit('handleSubstituteClick', teacher)"
                                                    >
                                                        <span
                                                            style="color: white"
                                                            class="pl-2 pr-2"
                                                        >
                                                            Wählen
                                                        </span>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Lehrer*in zuweisen
                                                </span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vue-custom-scrollbar>
                </div>
                <div
                    v-if="appointment"
                    class="pl-2 pr-2  "
                >
                    <h1 style="font-size: 18px; color:red;">
                        Abwesende Lehrer
                    </h1>
                </div>
                <div
                    v-if="appointment"
                    class="d-flex flex-row align-center  pl-4 pr-2"
                    style="flex:auto; background-color:white;"
                >
                    <vue-custom-scrollbar
                        class="absent-scroll-area"
                    >
                        <div
                            v-for="(teacher,index) in unavailableTeachers"
                            :key="index"
                            class="d-flex flex-row  mr-4 mb-2"
                        >
                            <div
                                class="mr-1 d-flex align-center"
                            >
                                <Avatar
                                    :profile="teacher.account"
                                    :size="36"
                                />
                            </div>
                            <div class="d-flex flex-column">
                                <p style="font-size: 14px;">
                                    {{ teacher.name[0] + '. ' + teacher.lastName }}
                                </p>
                                <div
                                    v-if="teacher.substitutionNote"
                                    class="d-flex flex-row align-center"
                                >
                                    <img
                                        :src="infoIcon"
                                        class="icon20 mr-1"
                                        alt="Notiz"
                                    >
                                    <p
                                        style="font-size: 12px;  overflow-wrap: anywhere;"
                                    >
                                        {{ teacher.substitutionNote }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </vue-custom-scrollbar>
                </div>
            </div>
        </div>
        <div v-else>
            <div
                v-if="appointment"
                class="mb-2"
            >
                <h1 style="font-size: 18px; color:red;">
                    Abwesende Lehrer
                </h1>
            </div>
            <div
                v-if="appointment"
                class="d-flex flex-row mb-2"
                style="flex:auto; background-color:white;"
            >
                <div
                    v-for="(teacher,index) in unavailableTeachers"
                    :key="index"
                    class="d-flex flex-row align-center mr-4"
                >
                    <div
                        class="mr-1 d-flex align-center"
                    >
                        <Avatar
                            :profile="teacher.account"
                            :size="36"
                        />
                    </div>
                    <div class="d-flex flex-column">
                        <p style="font-size: 14px;">
                            {{ teacher.name[0] + '. ' + teacher.lastName }}
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="appointment">
                <h1 style="font-size: 18px;color:green;">
                    Verfügbare Lehrer
                </h1>
            </div>
            <div
                class="d-flex flex-row  mt-1"
            >
                <div
                    v-for="(teacher, index) in availableTeachers"
                    :key="index"
                    class="teacherRow mt-2 mx-2"
                    style="cursor: pointer"
                    @click="()=>{ reportTeacherSickDialog = true; selectedTeacher = teacher; }"
                >
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row ">
                            <div
                                class="d-flex"
                                style="align-items:self-end"
                            >
                                <v-badge
                                    :color="calculateColor(teacher.workHours,teacher.maxHours)"
                                    :content="`${Math.round(teacher.workHours)}/${teacher.maxHours}`"
                                    left
                                    offset-x="40"
                                    overlap
                                    offset-y="4"
                                >
                                    <div class="mr-2">
                                        <Avatar
                                            :profile="teacher.account"
                                            :size="36"
                                        />
                                    </div>
                                </v-badge>
                            </div>
                            <div
                                class="d-flex flex-column"
                            >
                                <v-tooltip
                                    top
                                    style="z-index: 99;"
                                    :disabled="myClasses(teacher._id).length < 1"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            style="font-size: 14px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 180px;"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ teacher.name[0] + '. ' + teacher.lastName }}
                                            <span
                                                v-if="myClasses(teacher._id).length > 0"
                                                style="white-space: nowrap;"
                                            >
                                                : {{ myClasses(teacher._id)[0] }}
                                                <span
                                                    v-if="myClasses(teacher._id).length > 1"
                                                    style="white-space: nowrap;"
                                                >
                                                    ,...
                                                </span>
                                            </span>
                                        </p>
                                    </template>
                                    <p style="max-width: 220px">
                                        {{ myClasses(teacher._id).join(', ') }}
                                    </p>
                                </v-tooltip>

                                <div
                                    class="d-flex flex-row"
                                >
                                    <div
                                        v-for="(subject,index) in teacher.schoolSubjects"
                                        :key="index"
                                        style="padding: 0 1px; "
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <img
                                                    :src="getIcon(subject)"
                                                    class="subjectIconStyle"
                                                    :class="!subject.uploadedIcon ? 'pa-1' : ''"
                                                    :style="`background-color: ${subject.uploadedIcon ? '' : subject.color}`"
                                                    v-on="on"
                                                >
                                            </template>
                                            <span>{{ subject.name }}</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                                <v-tooltip
                                    v-if="appointment"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            class="ml-2 pa-0"
                                            small
                                            style="height: 36px; width: 36px; border-radius: 12px; background-color: var(--v-success-base)"
                                            min-width="36px"
                                            v-on="on"
                                            @click="$emit('handleSubstituteClick', teacher)"
                                        >
                                            <v-icon
                                                class="pa-0"
                                                size="30px"
                                                color="white"
                                                alt="Lehrer*in zuweisen"
                                            >
                                                mdi-account-check-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>
                                        Lehrer*in zuweisen
                                    </span>
                                </v-tooltip>
                            </div>
                        </div>
                        <v-btn
                            class="align-self-center text-none mb-2"
                            elevation="1"
                            small
                        >
                            <img
                                :src="anwesendIcon"
                                alt="Für diese Stunde Abwesend melden"
                                style="height: 18px"
                            >
                            Abwesend melden
                        </v-btn>
                    </div>
                    <v-divider
                        class="ml-2"
                        inset
                        vertical
                    />
                </div>
            </div>
        </div>
        <div v-if="reportTeacherSickDialog">
            <v-dialog
                v-model="reportTeacherSickDialog"
                max-width="390"
                peristent
            >
                <v-card style="border-radius: 8px 8px 0 0;">
                    <v-card-title class="d-flex flex-row justify-space-between align-center pa-2 primary">
                        <h1 style="font-size: 22px; color: white">
                            Lehrer abwesend melden
                        </h1>
                        <v-btn
                            icon
                            @click="()=>{reportTeacherSickDialog = false; clearInputs();}"
                        >
                            <img
                                :src="schliessenIcon"
                                alt="schließen"
                                class="icon20 iconToWhite"
                            >
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="px-4">
                        <div class="d-flex flex-row justify-flex-start align-center mt-1">
                            <div
                                class="mr-1 d-flex align-center"
                            >
                                <Avatar
                                    :profile="selectedTeacher.account"
                                    :size="36"
                                />
                            </div>
                            <p
                                style="font-size: 20px; overflow: hidden;text-overflow: ellipsis; width: fit-content"
                            >
                                {{ selectedTeacher.name[0] + '. ' + selectedTeacher.lastName }}
                            </p>
                        </div>
                        <div
                            v-if="selectedTeacher.substitutionNote"
                            class="d-flex flex-row align-center"
                        >
                            <img
                                :src="infoIcon"
                                class="icon20 mr-1"
                                alt="Notiz"
                            >
                            <p
                                style="font-size: 12px; overflow-wrap: anywhere;"
                            >
                                {{ selectedTeacher.substitutionNote }}
                            </p>
                        </div>
                        <p
                            class="mt-4"
                            style="font-size: 20px;"
                        >
                            Fächer:
                        </p>
                        <v-row class="ml-1">
                            <div
                                v-for="(subject,index) in selectedTeacher.schoolSubjects"
                                :key="index"
                                class="ma-1 d-flex flex-row justify-space-around"
                            >
                                <img
                                    :src="getIcon(subject)"
                                    class="subjectIconStyle mr-1"
                                    :class="!subject.uploadedIcon ? 'pa-1' : ''"
                                    :style="`background-color: ${subject.uploadedIcon ? '' : subject.color}`"
                                >
                                <p style="font-size: 18px;">
                                    {{ subject.name }}
                                </p>
                            </div>
                        </v-row>
                        <div class="mt-4">
                            <p style="font-size: 18px;">
                                Zeitraum:
                            </p>
                            <div class="d-flex flex-row justify-space-between">
                                <v-menu
                                    v-model="menuFrom"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    offset-overflow
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateFrom"
                                            label="Von"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="dateFrom"
                                        :allowed-dates="weekdaysOnly"
                                        locale="de-DE"
                                        first-day-of-week="1"
                                        no-title
                                        show-week
                                    />
                                </v-menu>
                                <v-menu
                                    v-model="menuTill"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    offset-overflow
                                    :close-on-content-click="false"
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateTill"
                                            label="Bis: "
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="dateTill"
                                        :allowed-dates="weekdaysOnly"
                                        locale="de-DE"
                                        first-day-of-week="1"
                                        no-title
                                        show-week
                                    />
                                </v-menu>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            class="optionBtn text-none ml-2"
                            color="grau"
                            @click="()=>{reportTeacherSickDialog = false; clearInputs();}"
                        >
                            {{ 'Abbrechen' }}
                        </v-btn>
                        <v-spacer />
                        <v-btn
                            class="optionBtn text-none ml-2"
                            color="success"
                            dark
                            @click="postSickAppointmentsMaintainer()"
                        >
                            {{ 'Bestätigen' }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <AreYouSurePopup
            v-if="showAreYouSureNoSub"
            :show-dialog="showAreYouSureNoSub"
            :content-text-prop="'Sind sie sicher, dass sie die Stunde als Ausfall melden wollen?'"
            :header-color="'var(--v-hellblau-base)'"
            :ok-button-text="'Bestätigen'"
            :ok-button-icon="bestaetigenIcon"
            :enable-checkbox="true"
            :checkbox-text="'Eltern und Schüler informieren'"
            :checkbox-info-text="'Informiert Eltern und Schüler über Chat-Mitteilungen über entfall der Stunde.'"
            @okCheckboxTrue="() => { $emit('noSubstitution', true) }"
            @okCheckboxFalse="() => { $emit('noSubstitution', false) }"
            @close="showAreYouSureNoSub = false"
        />
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import * as backend from "../../../api/backend"
import vueCustomScrollbar from "vue-custom-scrollbar";
import bioIcon from "@/assets/Icons/FaceliftIcons/f-biologie-weiss.svg";
import chemieIcon from "@/assets/Icons/FaceliftIcons/f-chemie-weiss.svg";
import deutschIcon from "@/assets/Icons/FaceliftIcons/f-deutsch-weiss.svg";
import englischIcon from "@/assets/Icons/FaceliftIcons/f-englisch-weiss.svg";
import erdkundeIcon from "@/assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg";
import geschichteIcon from "@/assets/Icons/FaceliftIcons/f-geschichte-weiss.svg";
import matheIcon from "@/assets/Icons/FaceliftIcons/f-mathe-weiss.svg";
import musikIcon from "@/assets/Icons/FaceliftIcons/f-musik-weiss.svg";
import kunstIconWeiss from '@/assets/Icons/FaceliftIcons/f-kunst-weiss.svg';
import physikIcon from "@/assets/Icons/FaceliftIcons/f-physik-weiss.svg";
import politikIcon from "@/assets/Icons/FaceliftIcons/f-politik-weiss.svg";
import religionIcon from "@/assets/Icons/FaceliftIcons/f-religion-weiss.svg";
import sportIcon from "@/assets/Icons/FaceliftIcons/f-sport-weiss.svg";
import wirtschaftIcon from "@/assets/Icons/FaceliftIcons/f-wirtschaft-weiss.svg";
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import closeIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'
import anwesendIcon from "@/assets/Icons/FaceliftIcons/lehrer.svg";
import schliessenIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'
import Avatar from "@/components/SharedViews/Profile/Avatar";
import magnifierIcon from '@/assets/Icons/redesignV3/magnifier.svg';
import eTooltipVue from '../../eTooltip/eTooltip.vue';
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";

export default {
    name:"VertretungsplanTeacherList",
    components: {
        Avatar,
        vueCustomScrollbar,
        AreYouSurePopup,
    },
    props: {
        appointment: { required: false, type: Object },
        appointments: { required: true, type: Array },
        subjects: {required: true},
        icons: {required: true},
        teachers: {required: true},
        date: {required: true},
        mode: {required: false, type: String},
        requestAppointments:{type: Function},
    },
    data: () => ({
        magnifierIcon,
        showLoadingOverlay: false,
        showAreYouSureNoSub: false,
        searchLocation: '',
        searchTeacher:'',
        teachersByLocation: [],
        possibleFilters:['Verfügbarkeit','frühere Vertretung','Standort'],
        selectedFilters:[],
        allAvailableTeachers:[],
        availableTeachers:[],
        unavailableTeachers: [],
        reportTeacherSickDialog: false,
        selectedTeacher: {},
        dateFrom: '',
        dateTill: '',
        menuFrom: '',
        menuTill: '',

        bioIcon,
        chemieIcon,
        deutschIcon,
        englischIcon,
        erdkundeIcon,
        geschichteIcon,
        matheIcon,
        musikIcon,
        kunstIconWeiss,
        physikIcon,
        politikIcon,
        religionIcon,
        sportIcon,
        wirtschaftIcon,
        infoIcon,
        closeIcon,
        anwesendIcon,
        schliessenIcon,
    }),
    computed: {
        ...mapGetters("auth",["accountRole"]),
        ...mapGetters("groups", ["groups", "groupsByLeader"]),
        ...mapGetters("accounts",["accountsById"]),
        ...mapGetters("teachers",["teachersById"]),
        ...mapGetters("appointments",["appointmentsById"]),
        ...mapGetters("location",['locations']),
        
        teachersWithLocations(){
            if(this.availableTeachers)
            {
                return this.availableTeachers.filter(teacher => teacher.locations?.length);
            }
            return []
        },
        filteredTeachersByLocation(){
            return this.teachersByLocation.filter((teacher) => teacher.locationName.toLowerCase().includes(this.searchLocation.toLowerCase()));
        },
        searchedAvailableTeachers(){
            return this.availableTeachers.filter((teacher) => teacher.name.toLowerCase().includes(this.searchTeacher.toLowerCase()));

        }
    },
    watch: {
        selectedFilters(newVal){
            this.filterAvailableTeachers(newVal);
        }
    },
    async mounted() {
        await this.getAvailableTeachers();
    },
    methods: {
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("appointments", ["getTeacherAppointmentsForDates"]),
        ...mapActions("groups", ["getGroups", "setGroupInvalidStatus"]),
        ...mapActions('teachers', ['getTeachers','getMeTeacher','getProfilePicture', 'createTeacher', 'deleteTeacher', 'editTeacher']),
        ...mapActions("copypaste", ["turnOffCopyMode"]),
        ...mapActions("location",['getLocations']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        
        clickNoSubstitution(){
            this.showAreYouSureNoSub = true;
        },
        remove (item) {
            this.selectedFilters.splice(this.selectedFilters.indexOf(item), 1)
        },
        /**
         * Gets the dates from all workdays of a week with a given day in the specific week
         * (Reference/origin: https://stackoverflow.com/questions/43008354/js-get-days-of-the-week)
         * @param dayInWeek the day in the week from which you want to get all workdays
         * @returns {*[]} an array containing the 5 workdays that are in the same week as dayInWeek
         */
        getWorkDaysOfWeek(dayInWeek) {
            dayInWeek = new Date(dayInWeek);
            const week = [];
            // Starting Monday not Sunday
            dayInWeek.setDate((dayInWeek.getDate() - dayInWeek.getDay() +1));
            for (let i = 0; i < 5; i++) {
                week.push(
                    new Date(dayInWeek)
                );
                dayInWeek.setDate(dayInWeek.getDate() +1);
            }
            return week;
        },

        async getAvailableTeachers(){
            const getMaxHours = (teacher) =>{
                const maxHours = teacher.hoursPerWeek ? teacher.hoursPerWeek : '40';

                return maxHours;
            };
            const calculateWorkHoursPerWeek = async (teacher) =>{
                const appointmentsWithIds = this.appointments.map(appointment =>({
                    ...appointment,
                    teacherIds : appointment.teachers.map(teacher => teacher._id),
                }))
                const teacherAppointments = appointmentsWithIds.filter(appointment => appointment.teacherIds.includes(teacher._id));
                const datesOfWeek = this.getWorkDaysOfWeek(this.date).map(date => date.toISOString().slice(0,10));
                let appointmentsOfWeek = datesOfWeek.map(date =>
                     teacherAppointments.filter(appointment => appointment.day.slice(0,10) === date),
                );
                // list consists of appointments per weekday, since we need all appointments combined anyways we flatten array
                appointmentsOfWeek = [].concat.apply([], appointmentsOfWeek);

                const responses = await Promise.all(appointmentsOfWeek.map(async (appointment) => {
                    return await backend.getTimeslotLength(this.accountRole === 'maintainer' ? appointment.timeslot : appointment.timeslot._id);
                }));
                const lengths = await Promise.all(responses.map(async(res) => res.json()));
                const weekLength = lengths.reduce((partialSum, a) => partialSum + a, 0);
                //lengths are in ms, ms -> min = 1/3600000
                const hrsPerWeek = weekLength / 3600000;

                const rounded = Number(hrsPerWeek).toFixed(2);
                return rounded;
            };
            this.showLoadingOverlay = true;
            let allTeachers = this.teachers;
            allTeachers = await Promise.all(allTeachers.map(async(teacher) => ({
                ...teacher,
                workHours: await calculateWorkHoursPerWeek(teacher),
                maxHours: getMaxHours(teacher),
                schoolSubjects: this.getSubs(teacher._id),
            })));

            allTeachers.forEach((teacher) => {
                if(this.appointment && this.appointment.substitutions && this.appointment.substitutions.some(el => el.sickTeacher._id === teacher._id)) {
                    this.unavailableTeachers.push(teacher);
                } else {
                    this.availableTeachers.push(teacher);
                }
            });
            //sort by least working hours
            this.availableTeachers = this.availableTeachers.sort((a,b)=> (a.workHours/a.maxHours) - (b.workHours/b.maxHours));
            this.showLoadingOverlay = false;
            this.allAvailableTeachers = this.availableTeachers;
        },
        // getAvailableDate(appointments){
        //     const fullAppointments = appointments.map((appointment) => this.appointmentsById[appointment]);
        //     const latest = fullAppointments[fullAppointments.length-1];
        //     const date = new Date(latest.day);
        //     const dateString =  date.toLocaleDateString('de-DE', { month: '2-digit', day: '2-digit', year: 'numeric' })
        //     return dateString;
        // },
        calculateColor(workHours, maxHours){
            const result = workHours/maxHours;
            return result <= 0.33 ? "green" : result <= 0.67 ? "orange" : "#ff0000";
        },
        getSubs(teacherId){
            return this.subjects.filter((subject) => {
                return subject.teachers.some((teacher) => teacher._id === teacherId)
            })
        },
        getIcon(subject){
            if(subject.uploadedIcon){
                const id = subject.icon;
                const returnValue =  this.icons.find(icon => icon.icon === id);
                return returnValue.iconUrl;
            }
            else{
                return this[subject.icon];
            }
        },
        closeDialog() {
            this.$emit("close");
        },
        formatDate(date) {
           return new Date(date).toLocaleDateString('de-DE', {
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    year: '2-digit'
                                                });
        },
        async postSickAppointmentsMaintainer() {
            try {
                const newDateFrom = new Date(this.dateFrom);

                const newDateTill = new Date(this.dateTill);
                const appointmentsFromTeacherInPeriod = await this.getTeacherAppointmentsForDates({teacherId: this.selectedTeacher._id, dateStart:newDateFrom, dateEnd: newDateTill});
                const appointmentIdsInPeriod = appointmentsFromTeacherInPeriod.map(appointment =>{ return appointment._id});
                if(appointmentIdsInPeriod.length === 0){
                    this.showSnackbar({
                        message: 'Der Lehrer besitzt keine Termine in eingegebenen Zeitraum',
                        color: 'error'
                    });
                    return;
                }
                if(appointmentIdsInPeriod.length > 0){
                    const resPost = await Promise.all(appointmentsFromTeacherInPeriod.map(async (appointment) => {
                        await backend.postSubstitution({
                            appointment: appointment._id,
                            sickTeacher: this.selectedTeacher._id,
                        });
                    }));
                    let message = '';
                    const formattedFrom = this.formatDate(this.dateFrom);
                    const formattedTill = this.formatDate(this.dateTill);
                    const fullTeacher = this.teachersById[this.selectedTeacher._id];
                    const dateString = formattedFrom === formattedTill ? `am ${formattedFrom} ganztägig` : `vom ${formattedFrom} bis einschließlich ${formattedTill}`;
                    message = `Die Lehrkraft ${fullTeacher.name} ${fullTeacher.lastName} wurde ${dateString} abwesend gemeldet.`
                    const check = await backend.postSubstitutionNotificationMaintainer({message});
                    if (this.sendNotificationToTeachers) {
                        const resNotification = await backend.postSubstitutionNotification({
                            appointments: appointmentIdsInPeriod,
                            sickTeacher: this.selectedTeacher._id,
                        })
                    }

                    await this.requestAppointments(appointmentIdsInPeriod);
                    this.clearInputs();
                    this.reportTeacherSickDialog = false;
                    this.showSnackbar({
                        message: 'Die Abwesenheiten wurde erfolgreich eingetragen!',
                        color: 'success'
                    });
                }
            } catch (err) {
                this.showSnackbar({
                    message: 'Bei dem Eintragen der Abwesenheit ist ein unerwarteter Fehler aufgetreten!',
                    color: 'error'
                });
                console.error(err);
            }
        },
        weekdaysOnly(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6;
        },

        // Source: http://stackoverflow.com/questions/497790
        convert(d) {
            // Converts the date in d to a date-object. The input can be:
            //   a date object: returned without modification
            //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
            //   a number     : Interpreted as number of milliseconds
            //                  since 1 Jan 1970 (a timestamp)
            //   a string     : Any format supported by the javascript engine, like
            //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
            //  an object     : Interpreted as an object with year, month and date
            //                  attributes.  **NOTE** month is 0-11.
            return (
                d.constructor === Date ? d :
                d.constructor === Array ? new Date(d[0],d[1],d[2]) :
                d.constructor === Number ? new Date(d) :
                d.constructor === String ? new Date(d) :
                typeof d === "object" ? new Date(d.year,d.month,d.date) :
                NaN
            );
        },
        inRange(d,start,end) {
            // Checks if date in d is between dates in start and end.
            // Returns a boolean or NaN:
            //    true  : if d is between start and end (inclusive)
            //    false : if d is before start or after end
            //    NaN   : if one or more of the dates is illegal.
            // NOTE: The code inside isFinite does an assignment (=).
            return (
                isFinite(d=this.convert(d).valueOf()) &&
                isFinite(start=this.convert(start).valueOf()) &&
                isFinite(end=this.convert(end).valueOf()) ?
                start <= d && d <= end :
                NaN
            );
        },
        clearInputs(){
            this.dateFrom = "";
            this.dateTill = "";
            this.menuFrom = "";
            this.menuTill = "";
        },
        myClasses(teacherId) {
            const classes = this.groupsByLeader(teacherId);
            let classNames = [];
            classes.map(myClass => {
                classNames.push(myClass.name);
            });
            return classNames;
        },
        async filterAvailableTeachers(filters){
            let returnList = this.allAvailableTeachers;
            if(filters.includes('Verfügbarkeit')){

                const midDay = new Date(new Date(this.appointment.day).getUTCFullYear(), new Date(this.appointment.day).getUTCMonth(),
                    new Date(this.appointment.day).getUTCDate(), 12);
                returnList = returnList.filter(teacher => teacher.maxHours > teacher.workHours);  
                const teachersFreeHour = await Promise.all(returnList.map(async teacher =>{
                    const res = await backend.getTeacherAppointmentAtTimeslotAndDate(teacher._id, this.appointment.timeslot, midDay);
                    if(res.status === 204){
                         return teacher;
                    }else if(res.status === 200){
                        //200 indicates that the teacher has ozher appointments in request
                        return 'occupied';
                    }
                    else{
                     this.showSnackbar({message: 'Beim Filtern ist ein Fehler aufgetreten. Verwenden sie andere Filter und wenden sich an den Support.', color: 'error'})
                     return
                   }
                }));
                returnList = teachersFreeHour.filter(teacher => teacher !== 'occupied').sort((a,b)=> (a.workHours/a.maxHours) - (b.workHours/b.maxHours));
                this.availableTeachers = returnList;
            }
            if(filters.includes('frühere Vertretung')){
                this.showLoadingOverlay = true;
                const res = await backend.getTeachersFromGroupByAppointment(this.appointment.group);
                const result = await res.json();
                const teacherIds = result[0].teacherIds;
                //filter for teachers that are also already in returnList
                returnList = returnList.filter((teacher)=> teacherIds.includes(teacher._id));
                this.availableTeachers = returnList;
            }
            if(filters.includes('Standort') && this.teachersWithLocations.length > 0){
                const unfilteredTeachersByLocation = this.locations.map((location) => {
                    console.log('ay',this.teachersWithLocations)
                    const teachers = this.teachersWithLocations.filter(teacher => teacher.locations.findIndex(teacherloc => teacherloc._id === location._id) >=0);
                   
                        return {locationName: location.name, teachers}
                }
                )
                this.teachersByLocation = unfilteredTeachersByLocation.filter(location => location.teachers.length > 0);
            }
                this.availableTeachers = returnList;

            this.showLoadingOverlay = false;
        }
    }
}
</script>

<style lang="scss" scoped>

.teacherRow{
    display: flex;
    flex-direction: row;
}
.scroll-area {
    margin: auto;
    height: auto;
    max-height: calc(50vh + 75px);
    border-radius: 8px 8px 8px 8px;
    overflow-x: hidden;
    width: auto;
}
.teacherIconStyle{
    width: 36px;
    height: 36px;
    border-radius: 8px;
}
.subjectIconStyle{
    width: 20px;
    height: 20px;
    border-radius: 4px;
}
.available-scroll-area {
    max-height: 45vh;
}
.absent-scroll-area {
    max-height: 100px;
}
</style>
