import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const defaultState = {};

const mutations = {};

const getters = {};

const actions = {
    async getSubstitutions({ commit, dispatch }) {
        try {
            const res = await backend.getSubstitutions();
            const substitutions = await res.json();
            await checkResponseStatus(200, res);
            return substitutions;
        }
        catch (err) {
            //console.error(err);
            return err?.response?.status;
        }
    },

    async patchSubstitution ({ commit, dispatch }, { substitutionId, params }) {
        try {
            const res = await backend.patchSubstitution(substitutionId, params);
            await checkResponseStatus(201, res);
            return await res.json();
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async selectNoSubstitution ({ commit, dispatch }, { substitutionId, params }) {
        try {
            const res = await backend.selectNoSubstitution(substitutionId, params);
            await checkResponseStatus(201, res);
            return await res.json();
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async postSubstitution({ commit, dispatch }, params) {
        try {
            const res = await backend.postSubstitution(params);
            await checkResponseStatus(201, res);
            return await res.json();
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },
}

export default {
    namespaced: true,
    state: defaultState,
    getters,
    mutations,
    actions,
};
