<template>
    <div
        :ref="`messageToHighlight${mappedChatRoomEvent._id}`"
        style="display: flex"
        :class="(fromMe(mappedChatRoomEvent) ? 'myMessage ' : ' ') + 'chat-message'"
    >
        <div v-if="!fileCorrupted">
            <!-- Message Header Area -->
            <div
                v-if="!(chatRoom.type === 'privateChat')"
                style="display: flex;"
            >
                <div
                    class="pa-0 greyText"
                    :class="chatRoom.type === 'privateChat' ? 'mt-2':''"
                >
                    <div>
                        <!-- For Teachers only, display avatar with Profile Picture -->
                        <!-- <v-avatar
                        v-if="teacherPic !== null"
                        class="mr-2"
                        medium
                        elevation="0"
                        fab
                        color="#6994cc"
                    >
                        <img
                            alt="Lehrer Profilbild"
                            :src="teacherPic ? teacherPic : lehrerIcon"
                            :style="teacherPic ? 'height: 48px' : 'height: 25px'"
                            :class="teacherPic ? 'roundImage' : 'iconToWhite'"
                        >
                    </v-avatar> -->
                        <v-tooltip 
                            v-if="!(chatRoom.type === 'privateChat')"
                            top
                        >
                            <template v-slot:activator="{ on }">
                                <p
                                    v-if="!mappedChatRoomEvent.isSystemMessage"
                                    :aria-hidden="true"
                                    tabindex="0"
                                    class="mb-0"
                                    :style="chatRoomEventListItemAuthor"
                                    style="cursor:pointer;"
                                    v-on="on"
                                >
                                    {{ mappedChatRoomEvent.authorDisplayName }}
                                </p>
                            </template>
                            <span>
                                {{ mappedChatRoomEvent.authorDisplayName }}

                            </span>
                        </v-tooltip>
                        <p
                            tabindex="0"
                            class="mb-0 visually-hidden"
                            style="width: fit-content; float: right"
                        >
                            {{
                                mappedChatRoomEvent.isSystemMessage ? 'Automatisierte Systemnachricht vom' :
                                `Nachricht von ${mappedChatRoomEvent.authorDisplayName} am ` +
                                new Date(getTime(mappedChatRoomEvent)).toLocaleDateString() +
                                " " +
                                getAccessibilityTime(
                                    new Date(getTime(mappedChatRoomEvent))
                                        .toLocaleTimeString()
                                        .slice(
                                            0,
                                            new Date(getTime(mappedChatRoomEvent)).toLocaleTimeString()
                                                .length - 3
                                        )
                                )
                            }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- Message Body Area -->
            <v-menu
                offset-y
                offset-overflow
                style="max-width:200px;"
                min-width="200"
            >
                <template v-slot:activator="{ on,attrs }">
                    <div
                        class="d-flex"
                        v-bind="attrs"
                        v-on="on"
                        @click="show"
                    >
                        <div
                            v-if="chatRoom.type==='groupChat'"
                            class="mr-2"
                        >
                            <Avatar
                                :profile="chatRoomEvent.author"
                                :size="50"
                                :show-burger="false"
                            />
                        </div>
                        <div
                            :style="messageStyle"
                            :class="hasShortReactions ? 'shortReactionPadding':'fullPadding'"
                            @click="selectMessage"
                        >
                            <div
                                v-if="mappedChatRoomEvent && mappedChatRoomEvent.answerTo"
                                class="d-flex flex-column justify-space-between answerToWrapper"
                                :style="`border-left: 5px inset ${getAuthorStyle(mappedChatRoomEvent.answerTo.author)}`"
                                @click.stop="jumpAnsweredTo"
                            >
                                <p
                                    style="font-size:14px; font-weight:bold"
                                    :style="{'color': fontColor}"
                                >
                                    {{ getAuthorName(mappedChatRoomEvent.answerTo.author) }}
                                </p>
                                <p
                                    class="answerMessageText"
                                    :style="{'color': fontColor}"
                                    v-html="sanitizeHtml(urlify(mappedChatRoomEvent.answerTo.text))"
                                />
                            </div>
                            <!-- Deleted Message -->
                            <div
                                v-if="mappedChatRoomEvent.isRedacted"
                                style="font-style: italic"
                            >
                                <p
                                    tabindex="0"
                                    class="mb-0"
                                    style="width: fit-content"
                                    :style="{'color': fontColor}"
                                >
                                    Diese Nachricht wurde gelöscht.
                                </p>
                            </div>

                            <!-- Text Message -->
                            <ChatRoomEventListTextItem
                                v-else-if="mappedChatRoomEvent.type === 'textMessage'"
                                :chat-room-event="mappedChatRoomEvent"
                                :chat-room-membership-role="chatRoomMembershipRole"
                                :font-color="fontColor"
                                :chat-search="chatSearch"
                            />

                            <!-- Audio Message -->
                            <ChatRoomEventListAudioItem
                                v-else-if="mappedChatRoomEvent.type === 'audioMessage'"
                                :chat-room-event="mappedChatRoomEvent"
                                :chat-room-membership-role="chatRoomMembershipRole"
                                :font-color="fontColor"
                            />

                            <!-- File Message -->
                            <ChatRoomEventListFileItem
                                v-else-if="mappedChatRoomEvent.type === 'fileMessage' || mappedChatRoomEvent.type === 'imageMessage'"
                                :key="forceReload"
                                :chat-room-event="mappedChatRoomEvent"
                                :chat-room="chatRoom"
                                :chat-room-membership-role="chatRoomMembershipRole"
                                :font-color="fontColor"
                            />

                            <!-- Poll Message -->
                            <Poll
                                v-else-if="mappedChatRoomEvent.type === 'pollMessage'"
                                :poll-id="mappedChatRoomEvent.poll"
                                :in-chat="true"
                                :font-color="fontColor"
                            />
                            <div
                                class="greyText"
                                style="font-size: smaller; min-width: 130px !important; margin-top: -5px;"
                            >
                                <v-menu
                                    left
                                    offset-y
                                    offset-overflow
                                    transition="scale-transition"
                                    origin="center"
                                    z-index="20"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            :aria-hidden="true"
                                            tabindex="0"
                                            style="float: right; margin-bottom: -5px !important;"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <span class="mr-1">
                                                <!-- {{ dayname[new Date(chatRoomEvent.createdAt).getDay()] }}. -->
                                                <!-- {{
                                            new Date(chatRoomEvent.createdAt).toLocaleDateString('de-DE', {
                                                day: '2-digit',
                                                month: '2-digit'
                                            })
                                        }} -->
                                                <span
                                                    class="hideText"
                                                    style="color: white"
                                                > {{
                                                    new Date(chatRoomEvent.createdAt).toLocaleTimeString('de-DE', {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })
                                                }}</span>

                                                <span
                                                    v-if="checkmark === 'none'"
                                                >
                                                    <img
                                                        :src="checkIcon"
                                                        class="iconToWhite  ml-1"
                                                        style="width: 16px; height: 16px; margin-bottom: -3px"
                                                    >
                                                </span>
                                                <span
                                                    v-if="checkmark === 'some'"
                                                >
                                                    <img
                                                        :src="checkAllIcon"
                                                        class="iconToWhite ml-1"
                                                        style="width: 16px; height: 16px; margin-bottom: -3px"
                                                    >
                                                </span>
                                                <span
                                                    v-if="checkmark==='all'"
                                                >
                                                    <img
                                                        :src="checkAllIcon"
                                                        class="iconToWhite ml-1"
                                                        style="width: 16px; height: 16px; margin-bottom: -2px"
                                                    >
                                                </span>
                                            </span>
                                        </p>
                                    </template>
                                    <v-list
                                        v-if="chatRoomEvent.author === account._id || accountRole === 'teacher' || accountRole === 'maintainer' || chatRoomMembershipRole === 'moderator'"
                                        dense
                                        color="rgba(97, 97, 97, 0.9)"
                                        style="width: 250px"
                                    >
                                        <v-list-item class="d-flex flex-row">
                                            <div style="color: white">
                                                Gelesen von:
                                            </div>
                                        </v-list-item>
                                        <v-list-item 
                                            class="d-flex"
                                            style="flex-direction: row-reverse; margin-top: -20px"
                                        >
                                            <div
                                                style="font-size: 12px; align-items: flex-end; color: white"
                                            >
                                                {{ "('" }}
                                                <img
                                                    :src="checkIcon"
                                                    class="iconToWhite"
                                                    style="width: 14px; height: 14px; margin-left: -5px; margin-right: -4px; margin-bottom: -2px"
                                                >
                                                {{ "' = erhalten, '" }}
                                                <img
                                                    :src="checkAllIcon"
                                                    class="iconToWhite"
                                                    style="width: 14px; height: 14px; margin-left: -3px; margin-right: -2px; margin-bottom: -2px"
                                                >
                                                {{ "' = gesehen)" }}
                                            </div>
                                        </v-list-item>
                                        <template
                                            v-for="(element, index) in readList"
                                        >
                                            <div
                                                :key="index + element.accId"
                                                style="background-color: transparent"
                                            >
                                                <v-divider />
                                                <div
                                                    class="d-flex flex-row justify-space-between px-2"
                                                >
                                                    <div
                                                        class="justify-flex-start"
                                                        style="font-size: 14px; color: white"
                                                    >
                                                        {{ element.name }}
                                                    </div>
                                                    <img
                                                        v-if="element.checkmark === 'read'"
                                                        :src="checkAllIcon"
                                                        class="iconToWhite justify-flex-end"
                                                        style="width: 14px; height: 14px; margin-bottom: -2px"
                                                    >
                                                    <img
                                                        v-else
                                                        :src="checkIcon"
                                                        class="iconToWhite justify-flex-end"
                                                        style="width: 14px; height: 14px; margin-bottom: -2px"
                                                    >
                                                </div>
                                            </div>
                                        </template>
                                    </v-list>
                                </v-menu>
                                <v-menu
                                    v-model="showMenu"
                                    :position-x="xPos -20"
                                    :position-y="yPos - 100"
                                    offset-y
                                    offset-overflow
                                    transition="scale-transition"
                                    origin="center"
                                    absolute
                                    :style="reactionList ? 'z-index:210 !important;' :'z-index:30 !important;'"
                                >
                                    <div
                                        class="d-flex flex-row align-center"
                                        style="cursor:pointer;" 
                                        :style="reactionList ? 'background-color: rgb(0,0,0,0.5)':'background-color:var(--v-headerblue-base); '"
                                    >
                                        <v-btn
                                            v-for="(item,index) in reactionItems"
                                            :key="index"
                                            icon
                                            dark
                                            x-large
                                            @click="reactToMessage(item)"
                                        >
                                            <div
                                                style="font-size:26px;"
                                            >
                                                {{ item }}
                                            </div>
                                        </v-btn>
                                        <v-tooltip
                                            v-if="clickedReaction && clickedReaction.account === account._id"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    icon
                                                    dark
                                                    x-large
                                                    v-on="on"
                                                    @click="removeMessageReaction(item)"
                                                >
                                                    <img
                                                        :src="cancelIcon"
                                                        class="icon30 iconToWhite"
                                                        alt="Entfernen"
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>Reaktion entfernen</span>
                                        </v-tooltip>
                                    </div>
                                </v-menu>
                            </div>
                            <div
                                v-if="hasShortReactions"
                                class="reactionPanel d-flex flex-row"
                                :style="`width:calc(28px * ${ mappedChatRoomEvent.shortReactions.length}); max-width: 78px; overflow: hidden !important;`"
                                @click.stop="showReactionList"
                            >
                                <div
                                    v-for="(shortReaction, index) in mappedChatRoomEvent.shortReactions"
                                    :key="shortReaction.account+index"
                                >
                                    {{ shortReaction.reactionEmoji }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <v-list
                    style="background-color:var(--v-headerblue-base);opacity:0.95; max-width: 200px; width:200px;cursor:pointer;"
                    class="pa-0"
                >
                    <v-list-item
                        class="d-flex flex-row align-center"
                        style="max-height:30px;"
                        @click="answerMessage"
                    >
                        <v-list-item-title
                            class="d-flex flex-row align-center"
                            style="color:white"
                        >
                            Antworten
                        </v-list-item-title>
                        <v-list-item-icon
                            class="d-flex flex-row align-center"
                        >
                            <v-icon color="white">
                                mdi-arrow-left
                            </v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item
                        class="d-flex flex-row align-center"
                        style="max-height:30px;"
                        @click="copyMessage"
                    >
                        <v-list-item-title
                            class="d-flex flex-row align-center"
                            style="color:white"
                        >
                            Kopieren
                        </v-list-item-title>
                        <v-list-item-icon
                            class="d-flex flex-row align-center"
                        >
                            <v-icon color="white">
                                mdi-content-copy
                            </v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list>
            </v-menu>
            <div v-if="mappedChatRoomEvent.isSystemMessage">
                <p style="font-size: small">
                    Automatisch generierte Nachricht.
                </p>
            </div>
        </div>
        <v-dialog
            v-if="reactionList"
            v-model="reactionList"
            max-width="300"
            z-index="23"
        >
            <v-card
                
                style="border-radius: 10px;"
            >
                <v-card-title
                    class="d-flex justify-space-between pa-2"
                    style="background-color: var(--v-chatColor-base)"
                >
                    <p style="color:white">
                        Reaktionen
                    </p>
                    <v-tooltip
                        bottom
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                elevation="0"
                                color="transparent"
                                v-bind="attrs"
                                v-on="on"
                                @click="reactionList = false"
                            >
                                <img
                                    :src="cancelIcon"
                                    class="icon20 iconToWhite"
                                >
                            </v-btn>
                        </template>
                        <span>Schließen</span>
                    </v-tooltip>
                </v-card-title>
                <v-card-text
                    v-if="hasShortReactions"
                    data-simplebar
                    class="scroll-area-reactions py-1 px-2"
                >
                    <v-list
                        v-if="hasShortReactions"
                    >
                        <div
                            v-for="(item,index) in mappedChatRoomEvent.shortReactions"
                            :key="index"
                            class="d-flex flex-row justify-space-between align-center"
                        >
                            <div class="d-flex flex-row justify-space-around align-center">
                                <Avatar
                                    :profile="chatRoomEvent.author"
                                    :size="35"
                                    :show-burger="false"
                                />
                                <div
                                    class="d-flex flex-column justify-space-around ml-2"
                                    :style="item.account === account._id ? 'cursor:pointer':''"
                                    @click="($event)=>{ showFromDialog($event,item);}"
                                >
                                    <p style="font-weight: bold; font-size: 16px !important">
                                        {{ getAuthorName(item.account) }}
                                    </p>
                                    <p
                                        v-if="item.account !== account._id"
                                        style="font-size: 12px !important"
                                    >
                                        {{ getReactionDate(item) }}
                                    </p>
                                    <p
                                        v-else
                                        style="font-size: 12px !important"
                                    >
                                        Hier klicken um zu ändern
                                    </p>
                                </div>
                            </div>
                            <div
                                class="d-flex flex-row justify-space-around" 
                                :style="item.account === account._id ? 'margin-right: -12px;':''"
                            >
                                <div
                                    style="font-size:30px !important;"
                                >
                                    {{ item.reactionEmoji }} 
                                </div>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-if="item.account === account._id"
                                            icon
                                            dark
                                            width="15"
                                            height="15"
                                            style="position: relative; top:-10px; right: 12px;"
                                            v-on="on"
                                            @click="removeMessageReaction(item)"
                                        >
                                            <img
                                                :src="cancelIcon"
                                                class="icon15 iconToDunkelgrau"
                                                alt="Entfernen"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Reaktion entfernen</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

import lehrerIcon from "@/assets/Icons/teacher.svg";
import checkIcon from "@/assets/Icons/FaceliftIcons/haeckehn_versendet.svg";
import checkAllIcon from "@/assets/Icons/FaceliftIcons/haeckehn_gesehen.svg";
import * as backend from "@/api/backend";
import Poll from "@/components/Poll";
import ChatRoomEventListFileItem from "./ChatRoomEventListFileItem";
import ChatRoomEventListAudioItem from "./ChatRoomEventListAudioItem";
import ChatRoomEventListTextItem from "./ChatRoomEventListTextItem";
import EventBus from "../../../../util/eventBus";
import Avatar from "@/components/SharedViews/Profile/Avatar";
import urlify from '@/util/urlify';
import sanitizeHtml from '@/util/sanitizeHtml';
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';

export default {
    name: "ChatRoomEventListItem",
    components: {
        Avatar,
        Poll,
        ChatRoomEventListFileItem,
        ChatRoomEventListAudioItem,
        ChatRoomEventListTextItem,
    },
    props: {
        chatRoomEvent: { required: true, type: Object },
        chatRoom: { required: true, type: Object },
        // Is the current account a 'moderator' or a 'user' in this chatroom
        chatRoomMembershipRole: { required: true, type: String },
        chatSearch:{ required: false, type: String, default:''},
        answerToMessage: {required: false, type: Object},
    },
    data: () => ({
        loadedTeacherPic: null,
        lehrerIcon,
        checkIcon,
        checkAllIcon,
        currentAccount: '',
        color: '',
        fontColor: 'black',
        forceReload: 0,
        cancelIcon,

        urlify,
        sanitizeHtml,
        reactionItems: ['👍','👎','❤️','🥰','🤦‍♂️'],
        showMenu: false,
        xPos: 0,
        yPos: 0,
        reactionList: false,
        dayname: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        clickedReaction: null,
    }),
    computed: {
        ...mapState("tooltips", ["showTooltips"]),
        ...mapState("auth", ['account', 'accountRole']),
        ...mapState('util', ['windowWidth']),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters('teachers', ['teachersByAccountId']),

        hasShortReactions(){
            return this.mappedChatRoomEvent.shortReactions && this.mappedChatRoomEvent.shortReactions.length > 0;
        },
        mappedChatRoomEvent() {
            return {
                ...this.chatRoomEvent,
                authorDisplayName:
                this.accountsById[this.chatRoomEvent.author].displayName,
                profileIcon:
                    this.accountsById[this.chatRoomEvent.author]
            };
        },
        ownChatRoomEventListItem() {
            return this.chatRoomEvent.author === this.currentAccount._id;
        },
        chatRoomEventListItemAuthor() {
            if (this.chatRoom.memberships.length === 2) {
                return `width: fit-content; height: fit-content; margin-left: 2px; max-width: ${this.windowWidth > 900 ? '10vw':'30vw'}; white-space:nowrap; overflow: hidden; text-overflow: ellipsis;`;
            } else {
                return "width: fit-content; height: fit-content; margin-left: 58px";
            }
        },
        messageStyle() {
            return  `max-width: ${this.chatRoom.type==='privateChat' ? '':this.windowWidth > 900 ? 'calc(15vw - 95px):':'calc(80vw - 100px)'};width: 100% !important;border-radius: 5px 5px 0px 5px !important; box-shadow: 1px 2px 3px silver; background-color: ${this.chatRoomEvent.isSystemMessage ? this.getSystemMessageColor : this.color}; color: black;`;
        },
        getSystemMessageColor(){
            if(this.chatRoomEvent.isSystemMessage && this.chatRoomEvent.systemMessageType){
                let returnVal;
                switch(this.chatRoomEvent.systemMessageType){
                    case 'user':
                        returnVal = 'var(--v-userColor-base)';
                        break;
                    case 'pupilSick' :
                        returnVal = 'var(--v-parentLetterPurple-base)';
                        break;
                    case 'chat' :
                        returnVal = 'var(--v-chatColor-base)';
                        break;
                    case 'teacherSick' :
                        returnVal = 'var(--v-timetableColor-base)';
                        break;
                    case 'exchange' :
                        returnVal = 'var(--v-fileArchiveOrange-base)';
                        break;
                    
                }
                return returnVal;
            }
            return 'var(--v-fgrau-base)'
        },
        checkmark() {
            let checkmarkStyle = 'some';
            let allMembershipAcc = [];
            let allSameTimestamps = true;
            let onlySeenByMe = false;
            let seenCounter = 0;
            for (let k = 0; k < this.chatRoom.memberships.length; k++) {
                for (let l = 0; l < this.chatRoom.memberships[k].addressedAccounts.length; l++) {
                    allMembershipAcc.push(this.chatRoom.memberships[k].addressedAccounts[l])
                }
            }

            const lastUpdated = this.chatRoomEvent.updatedAt;
            for (let j = 0; j < this.chatRoom.lastSeenTimestamps.length; j++) {
                const lastTime = this.chatRoom.lastSeenTimestamps[j].timestamp;
                if (lastTime < lastUpdated && this.chatRoom.lastSeenTimestamps[j].account != this.account._id) {
                    allSameTimestamps = false;
                } else {
                    seenCounter += 1;
                }
                if (lastTime >= lastUpdated && this.chatRoom.lastSeenTimestamps[j].account === this.account._id) {
                    onlySeenByMe = true;
                }
            }

            if (allSameTimestamps && allMembershipAcc.length === this.chatRoom.lastSeenTimestamps.length) {
                checkmarkStyle = 'all';
            }
            if (onlySeenByMe  && seenCounter === 1) {
                checkmarkStyle = 'none';
            }
            if (checkmarkStyle != 'all' && seenCounter > 1 && seenCounter != allMembershipAcc.length) {
                checkmarkStyle = 'some';
            }
            return checkmarkStyle;
        },

        readList() {
            let readList = [];
            let allMembershipAcc = [];

            for (let k = 0; k < this.chatRoom.memberships.length; k++) {
                for (let l = 0; l < this.chatRoom.memberships[k].addressedAccounts.length; l++) {
                    allMembershipAcc.push(this.chatRoom.memberships[k].addressedAccounts[l])
                }
            }

            const lastUpdated = this.chatRoomEvent.updatedAt;

            for (let i = 0; i < allMembershipAcc.length; i++) {
                if (allMembershipAcc[i] !== this.chatRoomEvent.author) {
                    let readListEl;
                    const accTimestamp = this.chatRoom.lastSeenTimestamps.find((timestamp) => timestamp.account === allMembershipAcc[i]);
                    if (!accTimestamp || lastUpdated > accTimestamp.timestamp) {
                        readListEl = {
                            accId: allMembershipAcc[i],
                            name: this.accountsById[allMembershipAcc[i]] ? this.accountsById[allMembershipAcc[i]].displayName :'',
                            checkmark: 'received'
                        }
                    } else if (lastUpdated <= accTimestamp.timestamp){
                        readListEl = {
                            accId: allMembershipAcc[i],
                            name: this.accountsById[allMembershipAcc[i]] ? this.accountsById[allMembershipAcc[i]].displayName :'',
                            checkmark: 'read'
                        }
                    }
                    readList.push(readListEl);
                }
            }
            return readList;
        },
        fileCorrupted(){
            if(this.chatRoomEvent.type === 'fileMessage' && !this.chatRoomEvent.file){
                return true;
            }
            return false;
        }
    },
    async mounted() {
        this.currentAccount = await this.getCurrentAccount();
        this.loadedTeacherPic = await this.getProfileIcon(this.chatRoomEvent.author);
        const whiteFontColors = ["#707070", "#d7875f", "var(--v-headerblue-base)", "rgb(52, 62, 85)", "#343e55",
            "#815a30", "#e6231e", "#95509C", '#3ba934'];
        whiteFontColors.forEach((color) => {
            if (this.color === color) {
                this.fontColor = "white";
            }
        });
        this.$emit('fontColor', this.fontColor);
        EventBus.$off('thumbnail-chat-ready');
        EventBus.$on('thumbnail-chat-ready', (eventId) => {
            if (this.chatRoomEvent._id !== eventId) {
                return;
            }
            this.forceReload++;
        });
    },
    async unmounted() {
        if (this.loadedTeacherPic) URL.revokeObjectURL(this.loadedTeacherPic);
    },
    methods: {
        ...mapActions('teachers', ['getTeachers','getProfilePicture', 'createTeacher', 'deleteTeacher', 'editTeacher']),
        ...mapActions('maintainers', ['getMaintainerProfilePicture', 'getMaintainers']),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        ...mapActions('auth', ['getCurrentAccount']),
        
        getReactionDate(chatRoomEvent){
            const eventdate = new Date(chatRoomEvent.changedAt || Date.now());
            
            const today = new Date();
            const isDateWithinLastWeek = (date) => {
                const now = new Date();
                
                const oneWeekAgo = new Date();  
                oneWeekAgo.setDate(now.getDate() - 7);
                
                const givenDate = new Date(date);
                
                return givenDate >= oneWeekAgo && givenDate <= now;
            };
            //check if date is today, or last 7 days, or older
            if(new Date(chatRoomEvent.changedAt || Date.now()).setHours(0,0,0,0) == today.setHours(0,0,0,0)){
                 return  'Heute, um ' + eventdate.toLocaleTimeString('de-DE', {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })
            }
            else if(isDateWithinLastWeek(chatRoomEvent.changedAt)){
                return `${this.dayname[eventdate.getDay()]}, ${eventdate.toLocaleTimeString('de-DE', {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}`
            }
            return  `${eventdate.toLocaleDateString('de-DE', {
                                                day: '2-digit',
                                                month: '2-digit'
                                            })} ${eventdate.toLocaleTimeString('de-DE', {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}`
        },
        showReactionList(){
            this.reactionList = !this.reactionList;
        },
        showFromDialog(e,item){
            if(this.showReactionList){
                this.clickedReaction = item;
                if(item.account === this.account._id){
                    this.showMenu = false;
                    e.preventDefault()
                    this.showMenu = false
                    this.xPos = e.clientX
                    this.yPos = e.clientY
                    this.$nextTick(()=>{
                        this.showMenu = true;
                    })
                }
            }
        },
        show(e){
                this.showMenu = false;
                e.preventDefault()
                this.showMenu = false
                this.xPos = e.clientX
                this.yPos = e.clientY
                this.$nextTick(()=>{
                    this.showMenu = true;
                })
        },
        async removeMessageReaction(item){
            const reactionRes = await backend.removeChatRoomEventReaction(this.chatRoom._id, this.mappedChatRoomEvent._id,);
            this.removeSelected();
            this.clickedReaction = null;
        },
        async reactToMessage(emoji){
            const reactionRes = await backend.postChatRoomEventReaction(this.chatRoom._id, this.mappedChatRoomEvent._id,{reactionEmoji:emoji});

            this.removeSelected();
        },
        selectMessage(){
            if(this.mappedChatRoomEvent.type !== 'textMessage'){
                return;
            }
            const element = this.$refs[`messageToHighlight${this.mappedChatRoomEvent._id}`];
             if (element) {
                    element.classList.remove('zindexhighlight');
                    element.classList.add('zindexhighlight');
             }
            this.$emit('selectedMessage',this.mappedChatRoomEvent._id);
        },
        removeSelected(){
            const element = this.$refs[`messageToHighlight${this.mappedChatRoomEvent._id}`];
             if (element) {
                    element.classList.remove('zindexhighlight');
             }
             this.$emit('removeSelectedMessage')
        },
        jumpAnsweredTo(){
            this.$emit('jumpToAnswered',this.mappedChatRoomEvent.answerTo._id)
        },
          getAuthorName(accountId){
             const account = this.accountsById[accountId];
            return account.displayName || '';
        },
        getAuthorStyle(accountId){
             const account = this.accountsById[accountId];
           
            return account.profileColor || '#f2f2f2';
        },
       answerMessage(){
        this.$emit('answerMessage',this.mappedChatRoomEvent._id)
         this.removeSelected();
       },
       copyMessage(){
        if(this.mappedChatRoomEvent.text){
            navigator.clipboard.writeText(this.mappedChatRoomEvent.text);
        }
        this.removeSelected();
        this.showSnackbar({message:'Nachricht kopiert',color:'success'});
       },
        async getProfileIcon(accountId){
            const account = await this.accountsById[accountId];
            if (account.role === 'teacher') {
                const teacher = await this.teachersByAccountId[accountId];
                if (teacher.profilePicture) {
                    if (this.ownChatRoomEventListItem) {
                        this.color = account.profileColor ? account.profileColor : 'var(--v-headerblue-base)' 
                    } else {
                        this.color= account.profileColor ? account.profileColor : 'var(--v-fileArchiveOrange-base)' 
                        this.fontColor = "white";
                    }
                    return await this.getProfilePicture(teacher._id);
                } else if (account.profileColor) {
                    this.color = account.profileColor;
                    // return profile icon or take standard icon (just in case something is missing in the db (profileColor/profilePicture)
                    return { color: account.profileColor ? account.profileColor : '#ff6633',
                        icon: !account.profilePicture ?  'mdi-emoticon-happy-outline' : account.profilePicture.img ? account.profilePicture.img
                            : account.profilePicture.text ? 'mdi-'+ account.profilePicture.text
                                : 'mdi-emoticon-happy-outline' }
                } else {
                    if (this.ownChatRoomEventListItem) {
                        this.color = "var(--v-headerblue-base)";
                    } else {
                        this.color = "var(--v-fileArchiveOrange-base)";
                        this.fontColor = "white";
                    }
                    // return standard icon
                    return { color: '#ff6633', icon: 'mdi-emoticon-happy-outline' }
                }
            } else if (account.role === 'maintainer') {
                // this only works if just 1 maintainer exists
                const maintainers = await this.getMaintainers();
                const maintainer = maintainers[0];
                if (maintainer.profilePicture) {
                    if (this.ownChatRoomEventListItem) {
                        this.color = "var(--v-headerblue-base)";
                    } else {
                        this.color = "var(--v-fileArchiveOrange-base)";
                        this.fontColor = "white";
                    }
                    return await this.getMaintainerProfilePicture(maintainer._id);
                } else {
                    if (account.profileColor) {
                        this.color = account.profileColor;
                    } else {
                        if (this.ownChatRoomEventListItem) {
                            this.color = "var(--v-headerblue-base)";
                        } else {
                            this.color = "var(--v-fileArchiveOrange-base)";
                            this.fontColor = "white";
                        }
                    }
                    // return profile icon or take standard icon
                    const color = account.profileColor ? account.profileColor : '#ff6633';
                    let icon = 'mdi-emoticon-happy-outline';
                    if (account.profilePicture) {
                        if (account.profilePicture.img) {
                            icon = account.profilePicture.img;
                        }
                        if (account.profilePicture.text) {
                            icon = 'mdi-' + account.profilePicture.text;
                        }
                    }
                    const iconObject = { color, icon };
                    return iconObject;
                }
            } else {
                if (account.profileColor) {
                    this.color = account.profileColor;
                } else {
                    if (this.ownChatRoomEventListItem) {
                        this.color = "var(--v-headerblue-base)";
                    } else {
                        this.color = "var(--v-fileArchiveOrange-base)";
                        this.fontColor = "white";
                    }
                }
                const iconObject = { color: account.profileColor ? account.profileColor : '#ff6633', icon: !account.profilePicture ?  'mdi-emoticon-happy-outline' : account.profilePicture.img ? account.profilePicture.img
                        : account.profilePicture.text ? 'mdi-'+ account.profilePicture.text
                            : 'mdi-emoticon-happy-outline' }
                return iconObject;
            }
        },
        getTime(event) {
            if (event.sentTime) {
                return event.sentTime;
            }
            event.sentTime = new Date(event.createdAt).toString();

            // TODO make sent timez
            // TODO: proper format/hide date if message was sent todaFy and always show time
            return new Date(event.createdAt).toString();
        },
        //name is sliced as it ends with the gender char. eg. Fritzm means name=Fritz, gender=male

        fromMe(event) {
            return event.author === this.account._id;
        },
        getAccessibilityTime(time) {
            const charArray = time.split("");

            let spelledOutTime = "";
            if (charArray[0] !== "0") {
                spelledOutTime = spelledOutTime + charArray[0];
            }
            spelledOutTime = spelledOutTime + charArray[1];
            spelledOutTime = spelledOutTime + " Uhr ";
            if (charArray[3] !== "0") {
                spelledOutTime = spelledOutTime + charArray[3];
            }
            spelledOutTime = spelledOutTime + charArray[4];
            return spelledOutTime;
        }
    }
};
</script>

<style>
.chat-send-input .v-input__append-inner {
    margin-top: 8px !important;
}

.chat-send-input .v-text-field__slot textarea {
    padding-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.chat-send-input {
    background-color: white;
    border-radius: 50px;
    width: 100%;
}

.chat-send-wrapper {
    display: inline-flex;
    background-color: #f2f2f2;
    padding: 0 12px;
    bottom: 0;
    left: 0;
    right: 0;
}

.group-chat-wrapper {
    flex: auto;
    max-height: calc(80vh - 55px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: white !important;
    border-radius: 0 0 15px 15px;
}

.chat-body {
    // position: fixed;
    // overflow-y: auto;
    flex: auto;
    //overflow-y: auto;
    //overflow-x: hidden;
    overflow: hidden;
}

.group-chat-header-btn {
    padding: 0 !important;
    min-width: 0 !important;
    border-radius: 8px;
    width: 36px !important;
    height: 36px !important;
}

.group-chat-header-text {
    color: white;
}

.group-chat-header {
    background-color: #95c94b; // 3caa68 - 5% Luminosity
    min-height: 56px;
    height: 56px;
}

.chat-header-btn {
    background-color: white !important;
    width: 35px;
    height: 35px !important;
}

.chat-header-img {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 176px);
    height: calc(80vh - 179px);
    overflow: hidden;
    margin-bottom: 2px;
}

.scroll-area-disabled {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 176px);
    height: calc(80vh - 179px);
    overflow: hidden;
    margin-bottom: 2px;
}

.chat-message {
    position: relative;
    border-radius: 5px;
    max-width: 75%;
    margin: 2px 2px 2px 19px;
}

.myMessage {
    margin-left: auto !important;
    margin-right: 19px !important;
}

/*removes darker background after element is clicked
also works without the specific class in front*/
.myMessage:before {
    opacity: 0 !important;
}
.chat-message:before {
    opacity: 0 !important;
}

.male-chat {
    background-color: var(--v-blau-base) !important;
}
.female-chat {
    background-color: var(--v-pink-base) !important;
}

.progressbar {
    border-radius: 5px;
}

.icon {
    height: 20px;
}

.bigIcon {
    height: 30px;
}

.pointer {
    cursor: pointer;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.iconToRecording {
    background-image: url("../../../../assets/Icons/recording-dot.svg") !important;
}

#micIcon {
    background-image: url("../../../../assets/Icons/FaceliftIcons/mikrofon_an.svg");
    width: 30px;
    height: 30px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
h2 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
}

#mandatoryFloatBtn {
    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translateY(-50%);
    bottom: 2%;
}

.formHeader {
    font-size: 16px;
    margin-bottom: 20px !important;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}

/* width */
::-webkit-scrollbar {
    width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 6px;
    width: 7px;
}
::-webkit-scrollbar-thumb:hover {
    background: #999;
    border-radius: 6px;
    width: 11px;
}

.iconToBlue {
    filter: brightness(0) saturate(100%) invert(49%) sepia(95%) saturate(213%) hue-rotate(173deg) brightness(98%) contrast(95%);
}

.iconToGreen {
    filter: brightness(0) saturate(100%) invert(47%) sepia(84%) saturate(398%) hue-rotate(69deg) brightness(96%) contrast(96%);
}

.answerToWrapper {
    background-color: rgba(0, 0, 0, 0.2); 
    padding: 0 4px 0 8px;
    border-radius: 6px;
}
.answerMessageText{
    max-width: 20vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
@media only screen and (max-width: 900px){
    .answerMessageText{
        max-width: 60vw;
    }
}

.zindexhighlight{
     z-index: 30!important; 
}

.reactionPanel{
    border-radius: 18px;
    background-color: rgba(0, 0, 0, 0.35);
    font-size: 18px !important;
    position: relative;
    top: 5px;
}
.shortReactionPadding{
    padding-top: .4rem;
    padding-left: .4rem;
    padding-right: .4rem;
    padding-bottom: 0;
}
.fullPadding{
    padding: .4rem;
}
.scroll-area-reactions {
    margin: auto;
    height: auto;
    max-height: 80vh;
    overflow-x: hidden
}
</style>
