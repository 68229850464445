<template>
    <div class="d-flex justify-space-between">
        <v-dialog
            v-if="showDialog"
            v-model="showDialog"
            overlay-opacity="0.7"
            width="500"
            style="align-items: center"
        >
            <v-card class="d-flex pa-3 justify-space-between">
                <v-btn
                    elevation="2"
                    x-small
                    class="mx-2 pa-0"
                    style="width: 30px; height: 30px"
                    @click="() => {showDialog = false; cancel()}"
                >
                    <img
                        alt="Hochladen abbrechen"
                        :src="papierkorbIcon"
                        class="iconToRed"
                        style="height: 20px"
                    >
                </v-btn>

                <audio
                    v-if="audioUrl"
                    id="myAudio"
                    ref="audioPlayback"
                    :src="audioUrl"
                    controls
                    style="width: 100%; height: 30px"
                >
                    Your browser does not support the audio tag.
                </audio>
                <div
                    class="d-flex flex-column align-center"
                    style="cursor: pointer; height: 35px; min-width: 30px; " 
                    @click.stop="togglePlaybackSpeed"
                >
                    <v-btn
                        x-small
                        icon
                        color="black"
                        class="pa-0"
                        @click.stop="togglePlaybackSpeed"
                    >
                        <v-icon>mdi-speedometer</v-icon>
                    </v-btn>
                    <span
                        style="font-size: 14px; color: black"
                    >{{ playbackSpeed }}x</span>
                </div>
                <v-btn
                    elevation="2"
                    x-small
                    class="pa-0 mx-2"
                    style="width: 30px; height: 30px"
                    color="secondary"
                    :aria-label="'Datei hochladen'"
                    @click="handleRecording(file)"
                >
                    <v-icon
                        x-small
                        dark
                    >
                        fas fa-check
                    </v-icon>
                </v-btn>
            </v-card>
        </v-dialog>
        <v-badge
            color="#ff0000"
            :content="countdown"
            :value="countdown"
            overlap
            left
            offset-x="15"
            offset-y="15"
            style="z-index: 9; width:100%"
        >
            <v-tooltip
                v-model="recordingTooltip"
                top
                :open-on-hover="false"
            >
                <template v-slot:activator="{ attrs }">
                    <v-btn
                        v-bind="attrs"
                        fab
                        icon
                        class="ma-0"
                        aria-label="Sprachaufnahme als Abgabe aufnehmen (gedrückt halten)"
                        max-width="38"
                        @keypress.enter="startAudioRecording()"
                        @keyup.enter="stopAudioRecording(2)"
                        @keypress.space="startAudioRecording()"
                        @keyup.space="stopAudioRecording(3)"

                        @click="
                            clickedAlready && !recordingRunning
                                ? ''
                                : recordingRunning
                                    ? stopAudioRecording()
                                    : startAudioRecording()
                            
                        "
                    >
                        <img
                            :src="recordingRunning ? recording : micIcon"
                            style="height: 30px"
                        >
                    </v-btn>
                </template>
                <span> {{ recordingTime }}</span>
            </v-tooltip>
        </v-badge>
        <v-overlay
            absolute
            :value="showLoadingOverlay"
            style="width: 100% !important; z-index: 500 !important;"
        >
            <v-progress-circular
                v-if="showLoadingOverlay"
                indeterminate
                size="100"
            />
        </v-overlay>
    </div>
</template>
<script>
import * as backend from "../../../api/backend";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import Recorder from "js-audio-recorder";
import startSound from "@/assets/Sound/startAufnahme.mp3";
import stopSound from "@/assets/Sound/exchanged.mp3";
import recording from "@/assets/Icons/recording-dot.svg";
import micIcon from "@/assets/Icons/FaceliftIcons/mikrofon_an.svg";
import papierkorbIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';

export default {
    name: "ChatRoomFileUploadButton",
    props: {
        chatRoomId: { required: true, type: String },
    },
    data: () => ({
        clickedAlready: false,

        recordingTime: "",
        elapsedTimeIntervalRef: "",
        recordingTooltip: false,
        recordingRunning: false,

        audioBlobs: [] /*of type Blob[]*/,
        mediaRecorder: null /*of type MediaRecorder*/,
        streamBeingCaptured: null /*of type MediaStream*/,

        audioStart: new Audio(startSound),
        audioStop: new Audio(stopSound),
        audioUrl: null,

        file: null,
        showDialog: false,
        papierkorbIcon,
        recording,
        micIcon,
        countdown: '',

        showLoadingOverlay: false,
        wakeLock: null,
        playbackSpeed: 1,
    }),
    computed: {
        ...mapGetters("auth", ["accountRole", "accountId"]),
        ...mapState("util", ["keyboard", "windowWidth"]),
    },
    watch: {
        recordingTime : {
            handler() {
                if (this.recordingTime === "02:00") {
                    this.stopAudioRecording();
                }
            }
        },
        showDialog(newVal){
            this.playbackSpeed = 1;
        }
    },
    methods: {
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions("chat", ["postChatRoomEvent", "postChatRoomEventFile"]),
        // async checkPermission() {
        //   try {
        //     return true;
        //   } catch (error) {
        //     console.error(`${error.name} : ${error.message}`);
        //     return false;
        //   }
        // },
        // hasEklaraMicPermissions() {
        //   return new Promise((resolve) => {
        //     navigator.mediaDevices.getUserMedia({audio: true}).then(function(stream) {
        //         resolve(true);
        //       }).catch(function(err) {
        //         resolve(false);
        //       })
        //   })
        // },
         async startWakeLock(){
            // create an async function to request a wake lock
            try {
            this.wakeLock = await navigator.wakeLock.request("screen");
            } catch (err) {
            // The Wake Lock request has failed - usually system related, such as battery.
            console.log(`${err.name}, ${err.message}`);
            }
        },
        async releaseWakeLock(){
              try {
                if (this.wakeLock) {
                // Release the wake lock and set it to null
                await this.wakeLock.release();
                this.wakeLock = null;

                }
            } catch (err) {
                console.error("Failed to release wake lock:", err);
            }
        },
         togglePlaybackSpeed() {
            const audio = document.getElementById("myAudio");
            this.playbackSpeed = this.playbackSpeed === 1 ? 1.5 : this.playbackSpeed === 1.5 ? 2 : 1;
            if(audio){
                audio.playbackRate = this.playbackSpeed;
            }
        },
        async startAudioRecording() {
            this.clickedAlready = true;
            try {
                if(navigator.mediaDevices === undefined) {
                    this.showSnackbar({ message: "Mikrofonaufnahmen werden in diesem Browser nicht unterstützt.",  color: "error"});
                    return;
                }
                await Recorder.getPermission();
            } catch(error) {
                console.error(error);
                this.showSnackbar({ message: "Fehler: Bitte aktiveren Sie Mikrofonaufnahmen für diese Seite.",  color: "error"});
                return;
            }

            await this.startWakeLock();
            //start recording using the audio recording API
            if (this.recordingRunning) {
                return; // Recording already running
            }

            const userMediaStream = await navigator.mediaDevices.getUserMedia(
                { audio: true } /*of type MediaStreamConstraints*/
            );

            //save the reference of the stream to be able to stop it when necessary
            this.streamBeingCaptured = userMediaStream;

            //create a media recorder instance by passing that stream into the MediaRecorder constructor
            this.mediaRecorder = new MediaRecorder(
                userMediaStream
            ); /*the MediaRecorder interface of the MediaStream Recording
      API provides functionality to easily record media*/

            //clear previously saved audio Blobs, if any
            this.audioBlobs = [];

            //add a dataavailable event listener in order to store the audio data Blobs when recording
            this.mediaRecorder.addEventListener("dataavailable", (event) => {
                //store audio Blob object
                this.audioBlobs.push(event.data);
            });

            this.audioStart.play();

            let intervalCount = 0;
            this.countdown = 3 - intervalCount;
            const countdownInterval = setInterval(() => {
                intervalCount++;
                this.countdown = 3 - intervalCount;

                if (this.countdown === 0) {
                    //start the recording by calling the start method on the media recorder
                    this.mediaRecorder.start();
                    this.countdown = '';
                    clearInterval(countdownInterval);
                    this.startTimer();
                    this.recordingRunning = true;
                    this.recordingTooltip = true;
                }
            }, 1000);
        },
        async stopAudioRecording(source) {
            if (!this.recordingRunning) {
                return; // Recording not started yet
            }

            const mediaRecorder = this.mediaRecorder;

            //listen to the stop event in order to create & return a single Blob object
            mediaRecorder.addEventListener("stop", () => {
                //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
                let audioBlob = new Blob(this.audioBlobs, {
                    type: mediaRecorder.mimeType ? mediaRecorder.mimeType : 'audio/webm',
                });

                this.saveAudioRecording(audioBlob);

            });
            await this.releaseWakeLock();

            //stop the recording feature
            this.recordingTooltip = false;
            this.recordingRunning = false;
            this.audioStop.play();
            this.mediaRecorder.stop();

            //stop all the tracks on the active stream in order to stop the stream
            this.streamBeingCaptured
                .getTracks() //get all tracks from the stream
                .forEach((track) /*of type MediaStreamTrack*/ => track.stop()); //stop each one      //reset API properties for next recording

            this.mediaRecorder = null;
            this.streamBeingCaptured = null;
            this.clickedAlready = false;
        },

        async saveAudioRecording(audioAsBlob) {
            //stop the recording using the audio recording API
            // const audioAsBlob = await this.stopRecording();

            this.file = audioAsBlob;
            this.audioUrl = URL.createObjectURL(this.file);
            this.showDialog = true;
            setTimeout(() => {
                this.stopTimer();
            }, 500);
            // console.error(error);
            // //Error handling structure
            // switch (error.name) {
            //     case 'InvalidStateError': //error from the MediaRecorder.stop
            //         console.log("An InvalidStateError has occurred.");
            //         break;
            //     default:
            //         console.log("An error occurred with the error name " + error.name);
            // }
        },
        cancel() {
            this.$refs.audioPlayback.pause();
            this.$refs.audioPlayback.currentTime = 0;
            URL.revokeObjectURL(this.audioUrl);
            this.audioUrl = null;
        },
        async handleRecording(blob) {
            this.showLoadingOverlay = true;
            const formData = new FormData();
            formData.append(
                "file",
                blob,
                new Date().toLocaleDateString().replaceAll(".", "_") + ".wav"
            );

            const createdChatRoomEventResponse = await this.postChatRoomEvent({
                type: "audioMessage",
                chatRoom: this.chatRoomId,
            });
            const createdChatRoomEvent = await createdChatRoomEventResponse.json();

            const fileXmlHttpRequest = await this.postChatRoomEventFile({
                chatRoomId: createdChatRoomEvent._id
            });

            fileXmlHttpRequest.onerror = (e) => {
                console.error("attachment upload error:", e);
                // this.showAttachmentUploadProgress = false;
            };
            fileXmlHttpRequest.onabort = () => {
                console.warn("attachment upload aborted");
                // this.showAttachmentUploadProgress = false;
            };
            fileXmlHttpRequest.addEventListener("load", () => {
                if (
                    createdChatRoomEventResponse.status === 201 &&
                    fileXmlHttpRequest.status === 201
                ) {
                    this.showSnackbar({ message: "Aufnahme versendet." });
                } else if (fileXmlHttpRequest.status !== 201) {
                    this.showSnackbar({
                        message: "Beim Hochladen der Aufnahme ist ein Fehler aufgetreten.",
                        color: "error",
                    });
                }
                // this.showAttachmentUploadProgress = false;
            });
            fileXmlHttpRequest.upload.addEventListener("progress", (e) => {
                // this.attachmentUploadProgress = (e.loaded / e.total) * 100;
            });
            // this.attachmentUploadProgress = 0.0;
            // this.showAttachmentUploadProgress = true;
            fileXmlHttpRequest.send(formData);
            this.showDialog = false;
            this.showLoadingOverlay = false;
        },
        startTimer() {
            var startTime = new Date();
            this.recordingTime = this.getElapsedTime(startTime);
            this.elapsedTimeIntervalRef = setInterval(() => {
                this.recordingTime = this.getElapsedTime(startTime);
            }, 1000);
        },
        stopTimer() {
            if (typeof this.elapsedTimeIntervalRef !== "undefined") {
                clearInterval(this.elapsedTimeIntervalRef);
                this.elapsedTimeIntervalRef = undefined;
            }
            this.recordingTime = "";
        },
        getElapsedTime(startTime) {
            // Record end time
            let endTime = new Date();
            // Compute time difference in milliseconds
            let timeDiff = endTime.getTime() - startTime.getTime();
            // Convert time difference from milliseconds to seconds
            timeDiff = timeDiff / 1000;
            // Extract integer seconds that don't form a minute using %
            let seconds = Math.floor(timeDiff % 60); //ignoring incomplete seconds (floor)
            // Pad seconds with a zero if necessary
            let secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
            // Convert time difference from seconds to minutes using %
            timeDiff = Math.floor(timeDiff / 60);
            // Extract integer minutes that don't form an hour using %
            let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, because they've been handled as seconds
            // Pad minutes with a zero if necessary
            let minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
            // Convert time difference from minutes to hours
            timeDiff = Math.floor(timeDiff / 60);
            // Extract integer hours that don't form a day using %
            let hours = timeDiff % 24; //no need to floor possible incomplete hours, because they've been handled as seconds
            // Convert time difference from hours to days
            timeDiff = Math.floor(timeDiff / 24);
            // The rest of timeDiff is number of days
            let days = timeDiff;
            let totalHours = hours + days * 24; // add days to hours
            let totalHoursAsString =
                totalHours < 10 ? "0" + totalHours : totalHours + "";
            if (totalHoursAsString === "00") {
                return minutesAsString + ":" + secondsAsString;
            } else {
                return (
                    totalHoursAsString + ":" + minutesAsString + ":" + secondsAsString
                );
            }
        },
    },
};
</script>
<style scoped>
.iconToRecording {
    background-image: url("../../../assets/Icons/recording-dot.svg") !important;
}

#micIcon {
    background-image: url("../../../assets/Icons/FaceliftIcons/mikrofon_an.svg");
    width: 30px;
    height: 30px;
}

.pointer {
    cursor: pointer;
}

.bigIcon {
    height: 30px;
}
</style>
