import {degrees, PageSizes, PDFDocument, rgb} from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import store from '@/store'; 
import roboto from "../assets/Fonts/Roboto-Regular.ttf";
import robotoBold from "../assets/Fonts/Roboto-Bold.ttf";


const removeHtml = (text) => {
    let returntext = new DOMParser().parseFromString(text, 'text/html');
    return returntext.body.textContent || '';
}
/**
 *
 * @param pages pages of this diary (always two there are)
 * @param fonts the fonts used in this diary
 * @returns {Promise<{JSON}>} Header measurements of each column, important for table setup
 */
const createHeaderForDiaryPDF = async (pages, fonts) => {
    const headerWithMeasurements = {};
    // create header lines
    for (let i = 0; i < 2; i++) {
        const currentPage = pages[i];
        const { width: pageWidth, height: pageHeight } = currentPage.getSize();
        const fontSize = 12;
        const fontHeight = fonts.robotoFont.heightAtSize(fontSize);
        const dayNameRectangleWidth = fontHeight + 3 + 3;
        const headerText = {
            timeslot: 'Std.',
            subject: 'Fach',
            teacher: 'Lehrer',
            topic: 'Unterrichtsinhalt',
            remark: 'Bemerkung',
        }
        const headerKeys = Object.keys(headerText);
        const outerPadding = {
            x: 10,
            y: 10,
        };
        let currentXOffset = 0;
        // create space for day name
        currentPage.drawRectangle({
            x: currentXOffset + outerPadding.x,
            y: pageHeight - outerPadding.y - dayNameRectangleWidth - 4,
            width: dayNameRectangleWidth,
            height: dayNameRectangleWidth + 4,
            color: rgb(0.9, 0.9, 0.9),
            borderWidth: 2,
            borderColor: rgb(0.3, 0.3, 0.3),
        });
        headerWithMeasurements['day'] = {
            x: currentXOffset + outerPadding.x,
            y: pageHeight - outerPadding.y - dayNameRectangleWidth - 4,
            rectWidth: dayNameRectangleWidth,
            rectHeight: dayNameRectangleWidth + 4,
        };

        currentXOffset = dayNameRectangleWidth;
        const compartementWidth = (pageWidth - dayNameRectangleWidth - outerPadding.x * 2) / 12;
        for (let j = 0; j < headerKeys.length; j++) {
            const key = headerKeys[j];
            const textWidth = fonts.robotoFont.widthOfTextAtSize(headerText[key], fontSize);
            const innerPadding = { top: 3, left: 10, bottom: 3, right: 10 };
            // update inner padding according to key
            switch (key) {
                case 'timeslot':
                    innerPadding.left = innerPadding.right = (0.80 * compartementWidth - textWidth) / 2;
                    break;
                case 'subject':
                    innerPadding.left = innerPadding.right = (1.4 * compartementWidth - textWidth) / 2;
                    break;
                case 'teacher':
                    innerPadding.left = innerPadding.right = (1.4 * compartementWidth - textWidth) / 2;
                    break;
                case 'remark':
                    innerPadding.left = innerPadding.right = (3 * compartementWidth - textWidth) / 2;
                    break;
                case 'topic':
                    innerPadding.left = innerPadding.right = (5.4 * compartementWidth - textWidth) / 2
                    break;
            }
            // draw background rectangle (text + borders + padding_inner)
            const rectangleHeight = fontHeight + 2 + 2 + innerPadding.top + innerPadding.bottom;
            const rectangleWidth = textWidth + innerPadding.left + innerPadding.right;
            // x needs all previous rectangle widths
            const rectangleX = outerPadding.x + currentXOffset;
            const rectangleY = pageHeight - outerPadding.y - rectangleHeight;
            currentPage.drawRectangle({
                x: rectangleX,
                y: rectangleY,
                width: rectangleWidth,
                height: rectangleHeight,
                color: rgb(0.9, 0.9, 0.9),
                borderWidth: 2,
                borderColor: rgb(0.3, 0.3, 0.3),
            });
            // draw wanted text into rectangle
            currentPage.drawText(headerText[key], {
                x: rectangleX + innerPadding.left, // to account for padding
                y: rectangleY + innerPadding.bottom + 2 + 2, // to account for padding and Border
                size: fontSize,
                font: fonts.robotoFont,
                color: rgb(0.1, 0.1, 0.1)
            });
            // update header with measurements
            headerWithMeasurements[`${key}`] = {
                text: headerText[key],
                x: rectangleX,
                y: rectangleY,
                innerPadding,
                rectWidth: rectangleWidth + 4,
                rectHeight: rectangleHeight,
                textWidth,
                textHeight: fontHeight,
            }

            // update current offset
            currentXOffset += rectangleWidth; // only +2 to have borders overlap
        }
        headerWithMeasurements['meta'] = {
            x: outerPadding.x,
            y: pageHeight - outerPadding.y - fontHeight + 2 + 2 + 6, // todo make this less hard coded
            width: currentXOffset,
            height: fontHeight + 2 + 2 + 6,
            margin: {
                bottom: outerPadding.y + 14,
                top: outerPadding.y
            }
        }
    }

    return headerWithMeasurements;
};
const breakTextToFitWidth = (text, maxWidth=300) =>{
    // Create a canvas element to measure text width
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '9px Roboto'; // Set the font to match the PDF rendering font

    let result = '';       // Final result string
    let line = '';         // Current line being constructed
    let currentWidth = 0;  // Accumulated width of the current line

    for (let i = 0; i < text.length; i++) {
        const char = text[i];
        const charWidth = context.measureText(char).width; // Measure the width of the current character

        if (currentWidth + charWidth > maxWidth) {
            // If adding this character exceeds the maxWidth, break the line
            result += line + '-\n'; // Append the current line with a hyphen and a newline
            line = char;            // Start a new line with the current character
            currentWidth = charWidth; // Reset the accumulated width for the new line
        } else {
            line += char;            // Append character to the current line
            currentWidth += charWidth; // Add the character width to the accumulated width
        }
    }

    result += line; // Append the last line without a hyphen

    return result;
}
const breakTextToFitWidthNew = (text, maxWidth=300) =>{
    // Create a canvas element to measure text width
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '9px Roboto'; // Set the font to match the PDF rendering font

    let result = '';       // Final result string
    let line = '';         // Current line being constructed
    let currentWidth = 0;  // Accumulated width of the current line
    const updatedText = text.replace(/\n/g, " ");
    for (let i = 0; i < text.length; i++) {
        const char = updatedText[i];
        const charWidth = context.measureText(char).width; // Measure the width of the current character

        if (currentWidth + charWidth > maxWidth) {
            // If adding this character exceeds the maxWidth, break the line
            result += line + '-\n'; // Append the current line with a hyphen and a newline
            line = char;            // Start a new line with the current character
            currentWidth = charWidth; // Reset the accumulated width for the new line
        } else {
            line += char;            // Append character to the current line
            currentWidth += charWidth; // Add the character width to the accumulated width
        }
    }

    result += line; // Append the last line without a hyphen

    return result;
}
const combineDiaryEntriesWithTimeslots = async (sortedDiaryEntries, timeslots) => {
    // console.log('sortedDiaryEntries', sortedDiaryEntries);
    return Object.keys(sortedDiaryEntries).reduce(async (previousPromise, entryKey) => {
        const previous = await previousPromise;
        const sortedEntry = sortedDiaryEntries[entryKey];
        const completeEntry = [];
        const participantsWithFullday = new Set(); // Track participants whose `isFullday` has been processed
        for (let i = 0; i < timeslots.length; i++) {
            const slot = timeslots[i];
            const currentEntry = { remark: '', subject: '', topic: '', timeslot: null, teacher: '' };
            currentEntry.timeslot = {
                start: new Date(slot.start).toLocaleTimeString('DE', { hour: "2-digit", minute: "2-digit" }),
                end: new Date(slot.end).toLocaleTimeString('DE', { hour: "2-digit", minute: "2-digit" })
            };
            const correspondingAppointment = sortedEntry.find(item => item.appointment && item.appointment.timeslot._id === slot._id);
            if (correspondingAppointment) {
                currentEntry.subject = correspondingAppointment.appointment.schoolSubject.name.substring(0, 10) || '';
                correspondingAppointment.appointment.teachers.forEach(teacher => {
                    currentEntry.teacher += `${teacher.name.substring(0, 1)}. ${teacher.lastName.substring(0, 1)}., `;
                });
                if (correspondingAppointment.appointment.appointmentDiaryEntryNote) {
                    currentEntry.topic = breakTextToFitWidthNew(removeHtml(correspondingAppointment.appointment.appointmentDiaryEntryNote.text), 235);
                    if (currentEntry.topic[currentEntry.topic.length - 1] !== '.') {
                        currentEntry.topic += '.';
                    }
                    if (correspondingAppointment.appointment.appointmentDiaryEntryNote.editHistory && correspondingAppointment.appointment.appointmentDiaryEntryNote.editHistory.length) {
                        currentEntry.topic += ' ¹*';
                    }
                }
                // Collect remarks
                const remarks = [];
                for (const participant of correspondingAppointment.pupils) {
                    if (
                        (participant.lateTime &&
                            !participant.lateTime.deleted &&
                            participant.lateTime.appointment === correspondingAppointment.appointment._id) ||
                        participant.diaryNote
                    ) {
                        let baseRemark = '';
                        if (participant.lateTime) {
                            if (participant.lateTime.isFullday) {
                                // Add 'fullday' only for the first timeslot
                                if (!participantsWithFullday.has(participant._id)) {
                                    if (i === 0) {
                                        baseRemark += 'fehlt ganzt.';
                                        participantsWithFullday.add(participant._id); // Mark as processed
                                    } else {
                                        continue; // Skip adding for subsequent timeslots
                                    }
                                } else {
                                    continue; // Already processed, skip entirely
                                }
                            } else if (participant.lateTime.missingTime) {
                                baseRemark += `${participant.lateTime.excused ? '' : 'un'}ent. ${participant.lateTime.missingTime} Min.`;
                            }
                            baseRemark += `${participant.lateTime.note ? ' ' + participant.lateTime.note : ''}`;
                            if (participant.lateTime.note.trim() !== '') {
                                baseRemark += ';';
                            }
                        }
                        if (participant.diaryNote) {
                            baseRemark += ' ' + participant.diaryNote;
                            if (
                                participant.diaryEntryNoteOnAppointment &&
                                participant.diaryEntryNoteOnAppointment.editHistory &&
                                participant.diaryEntryNoteOnAppointment.editHistory.length > 0
                            ) {
                                baseRemark += ' ²*';
                            }
                        }
                        if (baseRemark.trim()) {
                            remarks.push({ name: `${participant.name} ${participant.lastName[0]}`, remark: baseRemark.trim() });
                        }
                    }
                }
                // Consolidate redundant remarks
                const groupedRemarks = remarks.reduce((groups, { name, remark }) => {
                    if (!groups[remark]) {
                        groups[remark] = [];
                    }
                    groups[remark].push(name);
                    return groups;
                }, {});
                currentEntry.remark = Object.entries(groupedRemarks)
                    .map(([remark, names]) => `${names.join(', ')}: ${remark}`)
                    .join('\n');
            }
            currentEntry.remark = currentEntry.remark + '\n';
            completeEntry.push(currentEntry);
        }
        const sortedCompleteEntry = completeEntry.sort((a, b) => {
            return a.timeslot.start.localeCompare(b.timeslot.start);
        });
        previous[entryKey] = sortedCompleteEntry;
        return previous;
    }, Promise.resolve({}));
    
    
};

/**
 *
 * @param date date format of the current date
 * @param formattedEntryData
 * @param pages
 * @param fonts
 * @param positionMetaData
 * @returns {Promise<JSON>}
 */
const createWeekdayForDiaryPDF = (date, formattedEntryData, pages, fonts, positionMetaData, untouchedHeaderData) => {
    console.log(`createWeekdayForPDF with ${date}`);

    const innerPadding = { top: 3, right: 3, bottom: 3, left: 3 };
    const fontSize = 9;
    const lineHeight = fontSize + 2;
    // Remember: const headerText = {
    //             timeslot: 'Std.',
    //             subject: 'Fach',
    //             teacher: 'Lehrer',
    //             topic: 'Thema',
    //             appointmentNote: 'Fachinfo',
    //             remark: 'Weiteres',
    //         }

    // determine page
    const currentPageIndex = date.getDay() < 4 ? 0 : 1;
    let currentPage = pages[currentPageIndex];

    let runningMetaData = JSON.parse(JSON.stringify(positionMetaData));
    let deepestY = runningMetaData.timeslot.y;
    let overflow = null;

    // draws text contents into their correct positions
    const drawText = (data, dataKey, text = null) => {
        const metaData = runningMetaData[dataKey];
        const intendedText = text || (data[dataKey] || '');
        let textWidth = 0;
        let intendedLineBreaks = 0;
        // handle intended line breaks for remark or topic
        if (intendedText.includes('\n')) {
            const sanitizedTextArray = intendedText.split('\n');
            intendedLineBreaks = sanitizedTextArray.length-1;
            sanitizedTextArray.forEach(line => {
                if (line) {
                    const newWidth = fonts.robotoFont.widthOfTextAtSize(line, fontSize);
                    textWidth = textWidth < newWidth ? newWidth : textWidth;
                    // TODO check why there is one line too much
                }
            })
        } else {
            textWidth = fonts.robotoFont.widthOfTextAtSize(intendedText, fontSize);
        }
        const lineBreaks = Math.ceil(textWidth / metaData.rectWidth + 0.25) + intendedLineBreaks;

        // TODO maybe check every word if it is longer than maxWidth, scale fontsize accordingly
        currentPage.drawText(intendedText, {
            x: metaData.x + innerPadding.left,
            y: metaData.y - lineHeight, // to account for padding and Border
            size: fontSize,
            lineHeight: lineHeight,
            maxWidth: metaData.rectWidth - (innerPadding.right + innerPadding.left),// to account for padding
            font: fonts.robotoFont,
            color: rgb(0.1, 0.1, 0.1)
        });
        // update deepestY after every step for correct EOL line
        if (metaData.y - lineBreaks*lineHeight < deepestY) {
            deepestY = metaData.y - lineBreaks*lineHeight;
        }
    };

    for (let i = 0; i < formattedEntryData.length; i++) {
        // check for overflow and act accordingly
        if (deepestY < (runningMetaData.meta.margin.bottom + 3*lineHeight) && date.getDay() === 3) {
            console.log('overflow on wednesday achieved');
            overflow = {
                happened: true,
                lastY: Number.parseInt(`${deepestY}`)
            };
            runningMetaData = JSON.parse(JSON.stringify(untouchedHeaderData));
            deepestY = runningMetaData.day.y;
            currentPage = pages[1];
        }

        const data = formattedEntryData[i];
        // timeslot first
        drawText(data, 'timeslot', `${data.timeslot.start}\n${data.timeslot.end}`);

        // next up: subject name
        drawText(data, 'subject');

        // following: teacher name
        drawText(data, 'teacher');

        // dont forget the topic
        drawText(data, 'topic');

        // lastly print the remarks
        drawText(data, 'remark');

        // to give a little extra padding
        deepestY = deepestY - innerPadding.bottom;

        // draw the line at the end
        const { width } = currentPage.getSize();
        currentPage.drawLine({
            start: {x: runningMetaData.timeslot.x, y: deepestY},
            end: { x: width - runningMetaData.meta.x, y: deepestY },
            thickness: 1,
            color: rgb(0, 0, 0)
        });

        // In the end: Update all y to the deepestY
        runningMetaData.timeslot.y = deepestY;
        runningMetaData.subject.y = deepestY;
        runningMetaData.teacher.y = deepestY;
        runningMetaData.topic.y = deepestY;
        runningMetaData.remark.y = deepestY;
    }

    // function: day header on the side
    const drawDayText = (pageIndex, text, y, height) => {
        const biggerFontSize = 12;
        const biggerTextHeight = fonts.robotoFont.heightAtSize(biggerFontSize);
        const dayMeta = positionMetaData.day;
        const dayTextWidth = fonts.robotoFont.widthOfTextAtSize(text, biggerFontSize);
        const dayPadding = (height - dayTextWidth) / 2;
        pages[pageIndex].drawRectangle({
            x: dayMeta.x,
            y: y,
            width: dayMeta.rectWidth,
            height: height,
            color: rgb(0.9, 0.9, 0.9),
            borderWidth: 2,
            borderColor: rgb(0.3, 0.3, 0.3),
        });
        // draw wanted text into rectangle
        pages[pageIndex].drawText(text, {
            x: dayMeta.x + innerPadding.left + biggerTextHeight - 2, // to account for padding
            y: y + dayPadding + 2 + 2, // to account for padding and Border
            rotate: degrees(90),
            size: biggerFontSize,
            font: fonts.robotoFont,
            color: rgb(0.1, 0.1, 0.1)
        });
    };

    // function: draw lines between columns
    const drawDayLines = (pageIndex, topY, bottomY) => {
        const headerKeys = Object.keys(formattedEntryData[0]);
        for (let i = 0; i < headerKeys.length; i++) {
            const metaData = runningMetaData[`${headerKeys[i]}`];
            const xVal = metaData.x + metaData.rectWidth - 4; // account for both borders
            pages[pageIndex].drawLine({
                start: {
                    x: xVal,
                    y: topY
                },
                end: {
                    x: xVal,
                    y: bottomY
                },
                thickness: 1,
                color: rgb(0, 0, 0)
            });
        }
    };

    const dayOptions = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
    let dayText = date.toLocaleDateString('de-DE', dayOptions);
    const rectHeight = positionMetaData.timeslot.y - deepestY;
    // daytext position: complete height or if going over pagebreak height on first page
    // check if text fits in length in compartment height
    // overflow has it split
    if (overflow) {
        let continueText = `${date.toLocaleDateString('de-DE', { weekday: 'short' })} (fort.)`
        // draw part on page one first
        if (fonts.robotoFont.widthOfTextAtSize(dayText, 12) > (positionMetaData.timeslot.y - overflow.lastY)) {
            dayText = `${date.toLocaleDateString('de-DE', { weekday: 'short' })}`;
            continueText = date.toLocaleDateString('de-DE', dayOptions);
        }
        drawDayText(0, dayText, overflow.lastY, positionMetaData.timeslot.y - overflow.lastY);
        drawDayText(1, continueText, deepestY, untouchedHeaderData.timeslot.y - deepestY);

        // start line draw
        drawDayLines(0, positionMetaData.timeslot.y, overflow.lastY);
        drawDayLines(1, untouchedHeaderData.timeslot.y, deepestY);
    } else {
        drawDayText(currentPageIndex, dayText, deepestY, rectHeight);

        // start line draw
        drawDayLines(currentPageIndex, positionMetaData.timeslot.y, deepestY);
    }

    // return meta data of compartment
    runningMetaData.resetToNormalHeader = date.getDay() === 3 && !overflow;
    return runningMetaData;
};

const addAndFillPages = async (pdfDoc, fonts, sortedDiaryEntries, timeslots, groupName) => {
    pdfDoc.addPage(PageSizes.A4);
    pdfDoc.addPage(PageSizes.A4);
    const allPages = pdfDoc.getPages();
    const pages = [allPages[allPages.length - 2], allPages[allPages.length - 1]];
    // create header for each page
    const headerWithMeasurements = await createHeaderForDiaryPDF(pages, fonts);
    // combine entry data with timeslots and filter relevant information
    const formattedEntries = await combineDiaryEntriesWithTimeslots(sortedDiaryEntries, timeslots.filter(t=>t!==undefined));
    // call create for each Day
    let completeMetaData = JSON.parse(JSON.stringify(headerWithMeasurements));
    const untouchedHeaderData = JSON.parse(JSON.stringify(headerWithMeasurements));

    const dates = Object.keys(formattedEntries);
    for (let i = 0; i < dates.length; i++) {
        const entry = formattedEntries[dates[i]];
        const dayMetaData = createWeekdayForDiaryPDF(new Date(dates[i]), entry, pages, fonts, completeMetaData, untouchedHeaderData);
        // reset for page 2
        if (dayMetaData.resetToNormalHeader) {
            completeMetaData = {
                ...headerWithMeasurements
            };
        } else {
            completeMetaData = dayMetaData;
        }
    }
    // Todo for later: move this line to the top
    const weekNumber = `KW ${new Date(dates[0]).getWeek()}`;
    const weekDates = `Unterrichtswoche vom ${new Date(dates[0]).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})} bis ${new Date(dates[dates.length -1]).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})}`;
    const weekText = `${weekNumber} - ${weekDates}${groupName.length ? ' - '+groupName : ''}`;
    const attachmentText = '¹* Bearbeitet, siehe Anhang "Klassenbucheintrag Bearbeitungen", ²* Bearbeitet, siehe Anhang "Bemerkungen"'
    // add page numbers and week descriptor
    // pages[0].drawText(`${allPages.length - 1}`, {
    //     x: pages[0].getSize().width - completeMetaData.meta.x - fonts.robotoFont.widthOfTextAtSize('2', 10), // to account for padding
    //     y: 10, // to account for padding and Border
    //     size: 12,
    //     font: fonts.robotoFont,
    //     color: rgb(0.1, 0.1, 0.1)
    // });

    pages[0].drawText(attachmentText, {
        x: completeMetaData.meta.x, // to account for padding
        y:22, // to account for padding and Border
        size: 8,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    pages[0].drawText(weekText, {
        x: completeMetaData.meta.x, // to account for padding
        y: 10, // to account for padding and Border
        size: 12,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    // pages[1].drawText(`${allPages.length}`, {
    //     x: pages[1].getSize().width - completeMetaData.meta.x - fonts.robotoFont.widthOfTextAtSize('2', 10), // to account for padding
    //     y: 10, // to account for padding and Border
    //     size: 12,
    //     font: fonts.robotoFont,
    //     color: rgb(0.1, 0.1, 0.1)
    // });

    pages[1].drawText(attachmentText, {
        x: completeMetaData.meta.x, // to account for padding
        y:22, // to account for padding and Border
        size: 8,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    pages[1].drawText(weekText, {
        x: completeMetaData.meta.x, // to account for padding
        y: 10, // to account for padding and Border
        size: 12,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
};

const diaryWeekExport = async (sortedDiaryEntries, timeslots = [], groupName = '') => {
    console.log(`exportDiaryWeek`);
    console.log('sortedDiaryEntries', sortedDiaryEntries);
    console.log('timeslots', timeslots);
    // load default pdf structure (2 A4 pages)
    const pdfDoc = await PDFDocument.create();
    pdfDoc.registerFontkit(fontkit);
    const robotoBytes = await fetch(roboto).then((res) =>
        res.arrayBuffer()
    );
    const robotoBoldBytes = await fetch(robotoBold).then((res) =>
        res.arrayBuffer()
    );
    const robotoFont = await pdfDoc.embedFont(robotoBytes);
    const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);
    const fonts = {robotoFont, robotoBoldFont};
    await addAndFillPages(pdfDoc, fonts, sortedDiaryEntries, timeslots, groupName);
    

    // Serialize the PDFDocument to bytes (a Uint8Array)
    return await pdfDoc.save();
};

const exportMultipleDiaryWeeks = async ({
    sortedDiaryEntries,
    weeks,
    timeslots = [],
    groupName = '',
    pupilLateTimes = [],
    exportDiary = true,
    exportLateTimes = false,
    exportPupilDiaryNotes = false,
    exportAppointmentDiaryHistories = false,
    pupilsWithDiaryNotes = [],
    entriesWithHistories = [],
}) => {
    console.log(`exportMultipleDiaryWeeks`);
    console.log('weeks', weeks);
    console.log('sortedDiaryEntries', sortedDiaryEntries);
    console.log('timeslots', timeslots);
    // load default pdf structure (2 A4 pages)
    const pdfDoc = await PDFDocument.create();
    pdfDoc.registerFontkit(fontkit);
    const robotoBytes = await fetch(roboto).then((res) =>
        res.arrayBuffer()
    );
    const robotoBoldBytes = await fetch(robotoBold).then((res) =>
        res.arrayBuffer()
    );
    const robotoFont = await pdfDoc.embedFont(robotoBytes);
    const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);
    const fonts = {robotoFont, robotoBoldFont};

    if (exportDiary) {
        await weeks.reduce(async (previous, weekKeys) => {
            await previous;

            // filter sortedDiaryEntries for the current weekKeys
            const weekDiaryEntries = {};
            weekKeys.forEach(key => {
                weekDiaryEntries[key] = sortedDiaryEntries[key];
            });

            await addAndFillPages(pdfDoc, fonts, weekDiaryEntries, timeslots, groupName);
        }, Promise.resolve([]));
    }

    if (exportLateTimes) {
        await generateSickDaysForTimePeriod({
            pdfDoc, fonts, sortedDiaryEntries, weeks, timeslots, groupName, pupilLateTimes
        });
    }
    if(exportPupilDiaryNotes){
        await generatePupilDiaryNoteForTimePeriod({
            pdfDoc, fonts, sortedDiaryEntries, weeks, timeslots,pupilsWithDiaryNotes
        })
    }
    if(exportAppointmentDiaryHistories){
        await generateHistories({
            pdfDoc, fonts, groupName, sortedDiaryEntries, weeks, timeslots, entriesWithHistories
        })
    }

    //add page number at end of site
    const allPages = pdfDoc.getPages();
            
    allPages.forEach((page, index) => {
      page.drawText(`${index+1}`, {
        x: page.getSize().width - 10 - fonts.robotoFont.widthOfTextAtSize(`${pdfDoc.getPages().length}`, 10), // to account for padding
        y: 10, // to account for padding and Border
        size: 12,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    });
    // Serialize the PDFDocument to bytes (a Uint8Array)
    return await pdfDoc.save();
}
const getEditor = (id) =>{
    const accountObject = store.getters['accounts/accountsById'][id];
    if(accountObject.role === 'teacher'){
        const teacher = store.getters['teachers/teachersByAccountId'][id];
        return `${teacher.name[0]}.  ${teacher.lastName}`;
    }
    return 'Verwaltung';
}
const generatePupilDiaryNoteForTimePeriod = async ({pdfDoc, fonts, sortedDiaryEntries, weeks, timeslots = [], pupilsWithDiaryNotes}) => {
    let page = pdfDoc.addPage(PageSizes.A4);
    const robotoBytes = await fetch(roboto).then((res) =>
        res.arrayBuffer()
    );
    const robotoBoldBytes = await fetch(robotoBold).then((res) =>
        res.arrayBuffer()
    );
    const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);
    const boldFonts = { robotoBoldFont};


    // header
    const fontSizeHeader = 14;
    const fontSize = 12;
    const fontHeight = fonts.robotoFont.heightAtSize(fontSizeHeader);
    const fontHeightNormal = fonts.robotoFont.heightAtSize(fontSize);
    const padding = 3;
    const metaData = {
        title: { x: 10, y: page.getHeight() - 10 - fontHeight },
        names: { x: 10, y: page.getHeight() - 10 - 2*fontHeight - padding },
        subject: { x: 10, y: page.getHeight() - 10 - 2*fontHeight - padding },
        timeslot: { x: 10, y: page.getHeight() - 10 - 2*fontHeight - padding },
        diaryNote: { x: 10, y: page.getHeight() - 10 - 2*fontHeight - padding },
        
    };
    const lastWeek = weeks[weeks.length -1];
    const diaryNoteTitle = `Bemerkungen von ${new Date(weeks[0][0]).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})} bis ${new Date(lastWeek[lastWeek.length -1]).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})}`;
    page.drawText(diaryNoteTitle, {
        x: metaData.title.x,
        y: metaData.title.y, // to account for padding and Border
        size: fontSizeHeader,
        font: boldFonts.robotoBoldFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    page.drawText('SuS Namen', {
        x: metaData.names.x,
        y: metaData.names.y, // to account for padding and Border
        size: fontSizeHeader,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    metaData.subject.x = metaData.names.x + fonts.robotoFont.widthOfTextAtSize('SuS Namen', fontSizeHeader)+30;
    page.drawText('Fach', {
        x: metaData.subject.x,
        y: metaData.subject.y, // to account for padding and Border
        size: fontSizeHeader,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    metaData.timeslot.x = metaData.subject.x + fonts.robotoFont.widthOfTextAtSize('Fach', fontSizeHeader)*2 + 10;
    page.drawText('Uhrzeit', {
        x: metaData.timeslot.x,
        y: metaData.timeslot.y, // to account for padding and Border
        size: fontSizeHeader,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    metaData.diaryNote.x = metaData.timeslot.x + fonts.robotoFont.widthOfTextAtSize('Bemerkung', fontSizeHeader);
    page.drawText('Bemerkung', {
        x: metaData.diaryNote.x,
        y: metaData.diaryNote.y, // to account for padding and Border
        size: fontSizeHeader,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    let linePosY = (fontHeight + padding) + 1* padding + fontHeightNormal;
    const getPupilNote = (diaryNote) => {
        if(diaryNote.diaryEntryNoteOnAppointment && diaryNote.diaryEntryNoteOnAppointment.text.trim() !== ''){
            return breakTextToFitWidthNew(diaryNote.diaryEntryNoteOnAppointment.text.trim(), 280);
        }
        return 'Kein Eintrag';
    }
    const hasHistory = (diaryNote)=>{
        return diaryNote.diaryEntryNoteOnAppointment.editHistory && diaryNote.diaryEntryNoteOnAppointment.editHistory.length > 0 ? true : false;
    }

    Object.keys(pupilsWithDiaryNotes).forEach((pupilId, index) => {
        const pupilWithDiaryNotes = pupilsWithDiaryNotes[pupilId].concat(pupilsWithDiaryNotes[pupilId]);
        //can choose 0 since this is list of appointments from same pupil
        const fullName = pupilWithDiaryNotes[0].name + ' ' + pupilWithDiaryNotes[0].lastName; 
       if(index > 0){
        //draw seperation line after 1st pupil
            page.drawLine({
                start: {
                x: metaData.names.x,
                y: metaData.names.y - linePosY + 12,
                },
                end: {
                    x: metaData.diaryNote.x + 320,
                    y: metaData.timeslot.y - linePosY + 10,
                },
                thickness: 2.0,
                color: rgb(0, 0, 0)
        })
       }
        page.drawText(fullName, {
            x: metaData.names.x,
            y: metaData.names.y - linePosY - 10, // to account for padding and Border
            size: fontSize,
            font: fonts.robotoFont,
            color: rgb(0.1, 0.1, 0.1)
        });
        const filtered = pupilWithDiaryNotes.filter(entry=> entry.appointment.appointmentDiaryEntryNote !== undefined)
        filtered.forEach((diaryNote, innerIndex) => {
            if((750 - linePosY) < 40){
                page = pdfDoc.addPage(PageSizes.A4);
                const lastWeek = weeks[weeks.length -1];
                const diaryNoteTitle = `Bemerkungen von ${new Date(weeks[0][0]).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})} bis ${new Date(lastWeek[lastWeek.length -1]).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})}`;
                page.drawText(diaryNoteTitle, {
                    x: metaData.title.x,
                    y: metaData.title.y, // to account for padding and Border
                    size: fontSizeHeader,
                    font: boldFonts.robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                page.drawText('SuS Namen', {
                    x: metaData.names.x,
                    y: metaData.names.y, // to account for padding and Border
                    size: fontSizeHeader,
                    font: fonts.robotoFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.subject.x = metaData.names.x + fonts.robotoFont.widthOfTextAtSize('SuS Namen', fontSizeHeader)+30;
                page.drawText('Fach', {
                    x: metaData.subject.x,
                    y: metaData.subject.y, // to account for padding and Border
                    size: fontSizeHeader,
                    font: fonts.robotoFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.timeslot.x = metaData.subject.x + fonts.robotoFont.widthOfTextAtSize('Fach', fontSizeHeader)*2 + 10;
                page.drawText('Uhrzeit', {
                    x: metaData.timeslot.x,
                    y: metaData.timeslot.y, // to account for padding and Border
                    size: fontSizeHeader,
                    font: fonts.robotoFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.diaryNote.x = metaData.timeslot.x + fonts.robotoFont.widthOfTextAtSize('Bemerkung', fontSizeHeader);
                page.drawText('Bemerkung', {
                    x: metaData.diaryNote.x,
                    y: metaData.diaryNote.y, // to account for padding and Border
                    size: fontSizeHeader,
                    font: fonts.robotoFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                linePosY = (fontHeight + padding) + 1* padding + fontHeightNormal;
                page.drawText(fullName, {
                    x: metaData.names.x,
                    y: metaData.names.y - linePosY - 10, // to account for padding and Border
                    size: fontSize,
                    font: fonts.robotoFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
            }
            const timeslotStart = new Date(diaryNote.appointment.timeslot.start).toLocaleTimeString('DE', { hour: "2-digit", minute: "2-digit" });
            const timeslotEnd = new Date(diaryNote.appointment.timeslot.end).toLocaleTimeString('DE', { hour: "2-digit", minute: "2-digit" });
            
            page.drawText(diaryNote.appointment.schoolSubject.name, {
                x: metaData.subject.x,
                y: metaData.subject.y - linePosY - 10, // to account for padding and Border
                size: fontSize,
                maxWidth: metaData.names.x + fonts.robotoFont.widthOfTextAtSize('SuS Namen', fontSizeHeader)+20,
                font: fonts.robotoFont,
                color: rgb(0.1, 0.1, 0.1)
            });
            page.drawText(
            new Date(diaryNote.appointment.day).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'}) + 
                '\n' + '    ' + timeslotStart + 
                ' -\n' + '    ' + timeslotEnd , 
            {
                x: metaData.timeslot.x,
                y: metaData.timeslot.y - linePosY, // to account for padding and Border
                size: 10,
                lineHeight: 12,
                font: fonts.robotoFont,
                color: rgb(0.1, 0.1, 0.1)
            });

            page.drawText(getPupilNote(diaryNote).replace(/\r?\n|\r/g, " "), { //remove linebreaks to ensure text not overflowing    
                x: metaData.diaryNote.x,
                y: metaData.diaryNote.y - linePosY, // to account for padding and Border
                size: 10,
                lineHeight: 10,
                maxWidth: 320,
                font: fonts.robotoFont,
                color: rgb(0.1, 0.1, 0.1)
            });
            if (diaryNote.diaryEntryNoteOnAppointment.editHistory?.length) {
                linePosY += 40; // Add space before the "Bearbeitungen" header
                if ((750 - linePosY) > 60) {
                // Draw the edit history headers
                page.drawText('Bearbeitungen:', {
                    x: metaData.subject.x +5,
                    y: metaData.subject.y - linePosY,
                    size: 12,
                    font: fonts.robotoFont,
                    color: rgb(0.1, 0.1, 0.1),
                });
                page.drawText('Bearbeiter', {
                    x: metaData.subject.x + 90,
                    y: metaData.subject.y - linePosY,
                    size: 12,
                    font: fonts.robotoFont,
                    color: rgb(0.1, 0.1, 0.1),
                });
                page.drawText('Datum', {
                    x: metaData.subject.x + 160,
                    y: metaData.subject.y - linePosY,
                    size: 12,
                    font: fonts.robotoFont,
                    color: rgb(0.1, 0.1, 0.1),
                });
                page.drawText('ursprüngliche Notiz', {
                    x: metaData.subject.x + 210,
                    y: metaData.subject.y - linePosY,
                    size: 12,
                    font: fonts.robotoFont,
                    color: rgb(0.1, 0.1, 0.1),
                });
            }
                linePosY += 20; // Move below the headers
            
                // Loop through the edit history
                diaryNote.diaryEntryNoteOnAppointment.editHistory.forEach((edit, historyIndex) => {
                    // Check for page break
                    if ((750 - linePosY) < 40) {
                        page = pdfDoc.addPage(PageSizes.A4); // Add new page
                        const lastWeek = weeks[weeks.length -1];
                        const diaryNoteTitle = `Bemerkungen von ${new Date(weeks[0][0]).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})} bis ${new Date(lastWeek[lastWeek.length -1]).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})}`;
                        page.drawText(diaryNoteTitle, {
                            x: metaData.title.x,
                            y: metaData.title.y, // to account for padding and Border
                            size: fontSizeHeader,
                            font: boldFonts.robotoBoldFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        page.drawText('SuS Namen', {
                            x: metaData.names.x,
                            y: metaData.names.y, // to account for padding and Border
                            size: fontSizeHeader,
                            font: fonts.robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        metaData.subject.x = metaData.names.x + fonts.robotoFont.widthOfTextAtSize('SuS Namen', fontSizeHeader)+30;
                        page.drawText('Fach', {
                            x: metaData.subject.x,
                            y: metaData.subject.y, // to account for padding and Border
                            size: fontSizeHeader,
                            font: fonts.robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        metaData.timeslot.x = metaData.subject.x + fonts.robotoFont.widthOfTextAtSize('Fach', fontSizeHeader)*2 + 10;
                        page.drawText('Uhrzeit', {
                            x: metaData.timeslot.x,
                            y: metaData.timeslot.y, // to account for padding and Border
                            size: fontSizeHeader,
                            font: fonts.robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        metaData.diaryNote.x = metaData.timeslot.x + fonts.robotoFont.widthOfTextAtSize('Bemerkung', fontSizeHeader);
                        page.drawText('Bemerkung', {
                            x: metaData.diaryNote.x,
                            y: metaData.diaryNote.y, // to account for padding and Border
                            size: fontSizeHeader,
                            font: fonts.robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        linePosY = (fontHeight + padding) + 1* padding + fontHeightNormal;
                        page.drawText(fullName, {
                            x: metaData.names.x,
                            y: metaData.names.y - linePosY - 10, // to account for padding and Border
                            size: fontSize,
                            font: fonts.robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });

                        page.drawText('Bearbeitungen:', {
                            x: metaData.subject.x + 5,
                            y: metaData.subject.y - linePosY,
                            size: 12,
                            font: fonts.robotoFont,
                            color: rgb(0.1, 0.1, 0.1),
                        });
                        page.drawText('Bearbeiter', {
                            x: metaData.subject.x + 90,
                            y: metaData.subject.y - linePosY,
                            size: 12,
                            font: fonts.robotoFont,
                            color: rgb(0.1, 0.1, 0.1),
                        });
                        page.drawText('Datum', {
                            x: metaData.subject.x + 160,
                            y: metaData.subject.y - linePosY,
                            size: 12,
                            font: fonts.robotoFont,
                            color: rgb(0.1, 0.1, 0.1),
                        });
                        page.drawText('ursprüngliche Notiz', {
                            x: metaData.subject.x + 210,
                            y: metaData.subject.y - linePosY,
                            size: 12,
                            font: fonts.robotoFont,
                            color: rgb(0.1, 0.1, 0.1),
                        });
            
                        linePosY += 20; // Adjust for the new page header spacing
                    }
            
                    // Draw edit details (adjust positions and handle wrapping as needed)
                    const editorName = getEditor(edit.author) || 'Unbekannt';
                    const editDate = new Date(edit.editDate).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'}) + 
                    '\n' + '    ' + new Date(edit.editDate).toLocaleTimeString('DE', { hour: "2-digit", minute: "2-digit" });
                    
                    const originalNote = edit.previousText?.trim() || 'Keine ursprüngliche Notiz';
            
                    page.drawText(editorName, {
                        x: metaData.subject.x + 90,
                        y: metaData.subject.y - linePosY,
                        size: 10,
                        font: fonts.robotoFont,
                        color: rgb(0.1, 0.1, 0.1),
                    });
                    page.drawText(editDate, {
                        x: metaData.subject.x + 10,
                        y: metaData.subject.y - linePosY,
                        size: 10,
                        lineHeight: 12,
                        font: fonts.robotoFont,
                        color: rgb(0.1, 0.1, 0.1),
                    });
            
                    // Handle wrapping for the "originalNote"
                    const wrappedNote = breakTextToFitWidthNew(originalNote, 200); // Adjust width as needed
                    page.drawText(wrappedNote, {
                        x: metaData.subject.x + 210,
                        y: metaData.subject.y - linePosY,
                        size: 10,
                        font: fonts.robotoFont,
                        color: rgb(0.1, 0.1, 0.1),
                    });
                        linePosY += fontHeightNormal + 10; // Move down for each wrapped line
                });
                if(diaryNote.diaryEntryNoteOnAppointment.editHistory?.length > 0){
                    linePosY -= 15
                }
            }
            page.drawLine({
                start: {
                  x: metaData.subject.x,
                  y: metaData.timeslot.y - linePosY - 28,
                },
                end: {
                    x: metaData.diaryNote.x + 320,
                    y: metaData.timeslot.y - linePosY - 28,
                },
                thickness: 0.5,
                color: rgb(0, 0, 0)
            })
            linePosY = linePosY + 40
        });
    })
}
const generateSickDaysForTimePeriod = async ({pdfDoc, fonts, sortedDiaryEntries, weeks, timeslots = [], groupName = '', pupilLateTimes}) => {
    // add page at the end
    const page = pdfDoc.addPage(PageSizes.A4);
    const allPages = pdfDoc.getPages();

    // header
    const fontSizeHeader = 14;
    const fontSize = 12;
    const fontHeight = fonts.robotoFont.heightAtSize(fontSizeHeader);
    const fontHeightNormal = fonts.robotoFont.heightAtSize(fontSize);
    const padding = 3;

    const metaData = {
        title: { x: 10, y: page.getHeight() - 10 - fontHeight },
        names: { x: 10, y: page.getHeight() - 10 - 2*fontHeight - padding },
        missingTime: { x: 10, y: page.getHeight() - 10 - 2*fontHeight - padding },
        missingDays: { x: 10, y: page.getHeight() - 10 - 2*fontHeight - padding },
    };

    const lastWeek = weeks[weeks.length -1];
    const lateTimesTitle = `Fehltage von ${new Date(weeks[0][0]).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})} bis ${new Date(lastWeek[lastWeek.length -1]).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})}`;

    page.drawText(lateTimesTitle, {
        x: metaData.title.x,
        y: metaData.title.y, // to account for padding and Border
        size: fontSizeHeader,
        font: fonts.robotoBoldFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    // table header
    page.drawText('SuS Namen', {
        x: metaData.names.x,
        y: metaData.names.y, // to account for padding and Border
        size: fontSizeHeader,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    metaData.missingTime.x = metaData.names.x + fonts.robotoFont.widthOfTextAtSize('SuS Namen', fontSizeHeader)*4;
    page.drawText('Fehlstunden', {
        x: metaData.missingTime.x,
        y: metaData.missingTime.y, // to account for padding and Border
        size: fontSizeHeader,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    metaData.missingDays.x = metaData.missingTime.x + fonts.robotoFont.widthOfTextAtSize('Fehlstunden', fontSizeHeader)*2;
    page.drawText('Fehltage', {
        x: metaData.missingDays.x,
        y: metaData.missingDays.y, // to account for padding and Border
        size: fontSizeHeader,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
    // draw excused / unexcused headers
    const excusedText = 'ent.';
    const unexcusedText = 'unent.';
    const excusedWidth = fonts.robotoFont.widthOfTextAtSize(excusedText, fontSize);
    const unexcusedWidth = fonts.robotoFont.widthOfTextAtSize(unexcusedText, fontSize);
    page.drawText(excusedText, {
        x: metaData.missingTime.x,
        y: metaData.missingTime.y - padding - fontHeightNormal, // to account for padding and Border
        size: fontSize,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });

    page.drawText(unexcusedText, {
        x: metaData.missingTime.x + 2 * excusedWidth,
        y: metaData.missingTime.y - padding - fontHeightNormal, // to account for padding and Border
        size: fontSize,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });

    // excused/unexcsued for days
    page.drawText(excusedText, {
        x: metaData.missingDays.x,
        y: metaData.missingDays.y - padding - fontHeightNormal, // to account for padding and Border
        size: fontSize,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });

    page.drawText(unexcusedText, {
        x: metaData.missingDays.x + 2 * excusedWidth,
        y: metaData.missingDays.y - padding - fontHeightNormal, // to account for padding and Border
        size: fontSize,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });

    // draw separation line
    page.drawLine({
        start: {x: metaData.names.x, y: metaData.names.y - 2*padding - fontHeightNormal},
        end: { x: metaData.missingDays.x + 2 * excusedWidth + unexcusedWidth, y: metaData.names.y - 2*padding - fontHeightNormal},
        thickness: 1,
        color: rgb(0, 0, 0)
    });

    // go over late time array
    pupilLateTimes.forEach((lateTime, index) => {
        const linePosY = (index + 1) * (fontHeight + padding) + 3*padding + fontHeightNormal;

        page.drawText(lateTime.name, {
            x: metaData.names.x,
            y: metaData.names.y - linePosY, // to account for padding and Border
            size: fontSize,
            font: fonts.robotoFont,
            color: rgb(0.1, 0.1, 0.1)
        });
        page.drawText(lateTime.missingTimeString.excused, {
            x: metaData.missingTime.x,
            y: metaData.missingTime.y - linePosY, // to account for padding and Border
            size: fontSize,
            font: fonts.robotoFont,
            color: rgb(0.1, 0.1, 0.1)
        });

        page.drawText(lateTime.missingTimeString.unexcused, {
            x: metaData.missingTime.x + 2 * excusedWidth,
            y: metaData.missingTime.y - linePosY, // to account for padding and Border
            size: fontSize,
            font: fonts.robotoFont,
            color: rgb(0.1, 0.1, 0.1)
        });

        page.drawText(lateTime.missingDays.excused.toString(), {
            x: metaData.missingDays.x,
            y: metaData.missingDays.y - linePosY, // to account for padding and Border
            size: fontSize,
            font: fonts.robotoFont,
            color: rgb(0.1, 0.1, 0.1)
        });

        page.drawText(lateTime.missingDays.unexcused.toString(), {
            x: metaData.missingDays.x + 2 * excusedWidth,
            y: metaData.missingDays.y - linePosY, // to account for padding and Border
            size: fontSize,
            font: fonts.robotoFont,
            color: rgb(0.1, 0.1, 0.1)
        });
    })

    // in the eeeeeeeeeeeeeend draw page number
    page.drawText(`${allPages.length}`, {
        x: page.getSize().width - 10 - fonts.robotoFont.widthOfTextAtSize(`${allPages.length}`, 10), // to account for padding
        y: 10, // to account for padding and Border
        size: 12,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });
}
const generateHistories = async ({pdfDoc, fonts, groupName, sortedDiaryEntries, weeks, timeslots,entriesWithHistories}) => {
    const flattened = entriesWithHistories.flat(1);
    const lastWeek = weeks[weeks.length -1];
    const diaryNoteTitle = `${groupName}: Klassenbucheintrag Bearbeitungen von ${new Date(weeks[0][0]).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })} bis ${new Date(lastWeek[lastWeek.length -1]).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}`;

    const pageMargin = 20;
    const lineHeight = 15;
    const fontSize = 10;
    const fontSizeHeader = 12;

    // Initialize the first page
    let page = pdfDoc.addPage(PageSizes.A4);
    let currentY = page.getHeight() - pageMargin;

    // Function to draw the main header
    const drawHeader = () => {
        page.drawText(diaryNoteTitle, {
            x: pageMargin,
            y: currentY,
            size: fontSizeHeader,
            font: fonts.robotoBoldFont,
            color: rgb(0.1, 0.1, 0.1),
        });
        currentY -= 20;
    };

    // Function to draw the list header
    const positions = [
        pageMargin,
        (page.getWidth()  / 2) - pageMargin
    ];
    const drawListHeader = () => {
        const headers = ['Datum Stunde Fach,aktueller Eintrag', 'Datum Bearbeiter: Ursprünglicher Eintrag'];
      

        headers.forEach((header, i) => {
            page.drawText(header, {
                x: positions[i],
                y: currentY,
                size: fontSizeHeader,
                font: fonts.robotoBoldFont,
                color: rgb(0.1, 0.1, 0.1),
            });
        });

        currentY -= 20;
    };

    // Draw the header for the first page
    drawHeader();
    drawListHeader();
    const getDateString = (date, separator = ' ') =>{
        const editDate = new Date(date).toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'}) + 
        separator + new Date(date).toLocaleTimeString('DE', { hour: "2-digit", minute: "2-digit" });
        return editDate;
    };
    // Loop through appointments
    let index = 0;
   for (const appointment of flattened){
        const { day, schoolSubject, appointmentDiaryEntryNote } = appointment;
        const dateString = getDateString(day);
        const subjectName = schoolSubject.name;
        const editor = getEditor(appointmentDiaryEntryNote.author)|| 'Unbekannt';
        const noteText = removeHtml(appointmentDiaryEntryNote.text) || 'Kein Eintrag';
        const editHistory = appointmentDiaryEntryNote.editHistory || [];

        // Calculate and render the left section
        const leftSectionX = pageMargin;
        const leftSectionWidth = 1 / 2 * page.getWidth();
        if(index !== 0){
            page.drawLine({
                start: { x: pageMargin, y: currentY + 16 }, // start at the left margin
                end: { x: page.getWidth() - pageMargin, y: currentY + 16}, // end at the right margin
                thickness: 1,
                color: rgb(0, 0, 0),
            });
        }
        // Render date, subject name, editor
        const leftText = `${dateString} ${subjectName},`;
        let startY = currentY;
            page.drawText(leftText, {
                x: leftSectionX,
                y: currentY,
                size: fontSize +2,
                font: fonts.robotoFont,
                color: rgb(0.1, 0.1, 0.1),
            });
            currentY -= 12;
            page.drawText(`${getDateString(new Date(appointmentDiaryEntryNote.updatedAt))} ${editor} :`, {
                x: leftSectionX,
                y: currentY,
                size: fontSize,
                font: fonts.robotoFont,
                color: rgb(0.1, 0.1, 0.1),
            });
            const brokenDownText = breakTextToFitWidthNew(removeHtml(appointmentDiaryEntryNote.text), (positions[1] - 80))
            page.drawText(brokenDownText, {
                x: leftSectionX,
                y: currentY - 14,
                size: fontSize,
                lineHeight: 12,
                font: fonts.robotoFont,
                color: rgb(0.1, 0.1, 0.1), 
            });
            
            const outerLines = (String(brokenDownText).match(/\n/g) || '').length + 1;
        // Render the edit history
        //loop through reversed list to show newest edits at the top
         for (const historyEntry of editHistory.slice().reverse()) {
            if (currentY < pageMargin + 40) { // Check for page space
                page = pdfDoc.addPage(PageSizes.A4);
                currentY = page.getHeight() - pageMargin;
                drawHeader();
                drawListHeader();
                index = 0;
            }
            // Render editDate under 'Datum'
            const editDate = getDateString(historyEntry.editDate, ' ');
            const author = getEditor(historyEntry.author) || 'Unbekannt';
            const fullText = `${editDate} ${author}: ${historyEntry.previousText || 'Keine Einträge'}`;

            const wrappedText = breakTextToFitWidthNew(fullText, ((page.getWidth() / 2) - pageMargin));


            // Render previousText under 'Ursprünglicher Eintrag'
            let textY = currentY;

            wrappedText.split('\n').forEach((line) => {
                if (textY < pageMargin + 40) { // Check for page space
                    page = pdfDoc.addPage(PageSizes.A4);
                    textY = page.getHeight() - pageMargin;
                    drawHeader();
                    drawListHeader();
                    index = 0;
                }

                page.drawText(line, {
                    x: positions[1], // Position under 'Ursprünglicher Eintrag'
                    y: textY,
                    size: fontSize,
                    font: fonts.robotoFont,
                    color: rgb(0.1, 0.1, 0.1),
                });

                textY -= lineHeight;
            });

             const innerLines = (String(wrappedText).match(/\n/g) || '').length + 1;
            currentY -= (1+Math.max(outerLines, innerLines) * 17);

            currentY -= lineHeight; // Adjust for spacing between history rows
        }
        index ++;
    }
}
const exportLateTimesForPupil = async ({pupil, startDate, endDate, lateTimes}) => {
    // load default pdf structure
    const pdfDoc = await PDFDocument.create();
    pdfDoc.registerFontkit(fontkit);
    const robotoBytes = await fetch(roboto).then((res) =>
        res.arrayBuffer()
    );
    const robotoBoldBytes = await fetch(robotoBold).then((res) =>
        res.arrayBuffer()
    );
    const robotoFont = await pdfDoc.embedFont(robotoBytes);
    const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);
    const fonts = {robotoFont, robotoBoldFont};

    // add page to start
    const page = pdfDoc.addPage(PageSizes.A4);
    const allPages = pdfDoc.getPages();

    // header
    const fontSizeHeader = 14;
    const fontSize = 12;
    const lineHeight = fontSize + 2;
    const fontHeight = fonts.robotoFont.heightAtSize(fontSizeHeader);
    const fontHeightNormal = fonts.robotoFont.heightAtSize(fontSize);
    const padding = 3;
    const defaultHeaderY = page.getHeight() - 10 - 2*fontHeight - padding;

    const metaData = {
        title: { x: 10, y: page.getHeight() - 10 - fontHeight },
        date: { x: 10, y: defaultHeaderY, text: 'Zeitraum' },
        amount: { x: 10, y: defaultHeaderY, text: 'Fehlzeit' },
        excused: { x: 10, y: defaultHeaderY, text: 'ent.' },
        unexcused: { x: 10, y: defaultHeaderY, text: 'unent.' },
        note: { x: 10, y: defaultHeaderY, text: 'Bemerkung' },
    };

    const lateTimesTitle = `Fehlzeiten für ${pupil.lastName}, ${pupil.name} von ${startDate.toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})} bis ${endDate.toLocaleDateString('de-DE',{day: '2-digit',month: '2-digit',year: '2-digit'})}`;
    // draw title
    page.drawText(lateTimesTitle, {
        x: metaData.title.x,
        y: metaData.title.y, // to account for padding and Border
        size: fontSizeHeader,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });

    // draw normal header
    const headerKeys = Object.keys(metaData).filter(item => item !== 'title');
    for (let i = 0; i < headerKeys.length; i++) {
        const value = metaData[headerKeys[i]];
        // draw text
        page.drawText(value.text, {
            x: value.x,
            y: value.y, // to account for padding and Border
            size: fontSizeHeader,
            font: fonts.robotoFont,
            color: rgb(0.1, 0.1, 0.1)
        });

        const maxWidth = 1.75 * fonts.robotoFont.widthOfTextAtSize(value.text, fontSizeHeader);
        metaData[headerKeys[i]].maxWidth = headerKeys[i] === 'note' ? 2*maxWidth : maxWidth;

        if (i < headerKeys.length - 1) {
            metaData[headerKeys[i+1]].x = value.x + maxWidth;
        }
    }

    // draw separation line
    page.drawLine({
        start: {x: metaData.date.x, y: defaultHeaderY - padding},
        end: { x: metaData.note.x + fonts.robotoFont.widthOfTextAtSize(metaData.note.text, fontSizeHeader), y: defaultHeaderY - padding},
        thickness: 1,
        color: rgb(0, 0, 0)
    });

    // draw late times
    let linePosY = fontHeight + 3*padding;
    lateTimes.forEach((lateTime, index) => {
        let lineBreaks = 1;

        // declarations outside of switch statement
        const startDateLocale = new Date(lateTime.dateStart).toLocaleDateString('de-DE', { day: 'numeric', month: 'numeric', year: 'numeric' });
        const endDateLocale = new Date(lateTime.dateEnd).toLocaleDateString('de-DE', { day: 'numeric', month: 'numeric', year: 'numeric' });
        const hours = Math.floor(lateTime.missingTime/60);
        const minutes = lateTime.missingTime % 60;

        // reuse header keys for cleaner code
        for (let i = 0; i < headerKeys.length; i++) {
            const key = headerKeys[i];
            const value = metaData[key];

            // get text depending on key
            let text = '';
            switch (key) {
                case 'date':
                    // eslint-disable-next-line no-case-declarations
                    if (startDateLocale !== endDateLocale) {
                        text = `${startDateLocale}-${endDateLocale}`;
                    } else {
                        text = `${startDateLocale}`;
                    }
                    break;
                case 'amount':
                    // calculate missing time string
                    text = `${hours < 10 ? '0': ''}${hours}:${minutes < 10 ? '0': ''}${minutes}`;
                    break;
                case 'excused': text = lateTime.excused ? 'X' : ''; break;
                case 'unexcused': text = lateTime.excused ? '' : 'X'; break;
                case 'note': text = lateTime.note; break;
                default: text = value.text; break;
            }

            // calculate global linebreaks
            const textWidth = fonts.robotoFont.widthOfTextAtSize(text, fontSize);
            const localLineBreaks = Math.ceil(textWidth / value.maxWidth);
            if (localLineBreaks > lineBreaks) {
                lineBreaks = localLineBreaks;
            }

            // draw text
            allPages[allPages.length - 1].drawText(text, {
                x: value.x,
                y: value.y - linePosY, // to account for padding and Border
                size: fontSize,
                font: fonts.robotoFont,
                lineHeight,
                maxWidth: value.maxWidth,
                color: rgb(0.1, 0.1, 0.1)
            });

            // set new line pos
        }
        linePosY = linePosY + lineBreaks * (fontHeight + padding);
        if (linePosY <= 15) {
            // add new Page
            pdfDoc.addPage(PageSizes.A4);
            // reset line position
            linePosY = fontHeight + 3*padding;
        }
    });

    // in the eeeeeeeeeeeeeend draw page number and return
    page.drawText(`${allPages.length}`, {
        x: page.getSize().width - 10 - fonts.robotoFont.widthOfTextAtSize(`${allPages.length}`, 10), // to account for padding
        y: 10, // to account for padding and Border
        size: 12,
        font: fonts.robotoFont,
        color: rgb(0.1, 0.1, 0.1)
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    return await pdfDoc.save();
};

export default {
    diaryWeekExport,
    exportMultipleDiaryWeeks,
    exportLateTimesForPupil,
}
