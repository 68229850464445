/**
 * Choose a random index from a array
 * Optionally exclude indexes from selection.
 */
const generateRandomIndex = (arrayLength, excludedIndexes = []) => {
  let randomIndex = Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * arrayLength);
  while (excludedIndexes.includes(randomIndex)) { // Check if randomIndex is in excludedIndexes
    randomIndex = Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * arrayLength);
  }
  return randomIndex;
};


// https://stackoverflow.com/a/1431113
const replaceAt = (stringToManpulate, index, replacingWord) => {
  return stringToManpulate.substring(0, index) + replacingWord + stringToManpulate.substring(index + replacingWord.length);
}

// https://stackoverflow.com/a/51540480
const generateRandomPassword = ({ passwordLength = 8 }) => {
  if (passwordLength <= 2) {
    throw new Error("Password length cannot be less or equal than 2");
  }

  // Define character groups
  const numbers = "0123456789";
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const specialChars = ".,?!()€&@";
  const allChars = numbers + letters + specialChars;

  // Create random password
  const randomPassword = Array(passwordLength)
    .fill(allChars)
    .map((x) =>
      x[Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * x.length)]
    )
    .join('');

  // Ensure password has at least one number, one letter, and one special character
  const randomLetter = letters[Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * letters.length)];
  const randomNumber = numbers[Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * numbers.length)];
  const randomSpecialChar = specialChars[Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * specialChars.length)];

  const randomLetterIndex = generateRandomIndex(passwordLength, []);
  const randomNumberIndex = generateRandomIndex(passwordLength, [randomLetterIndex]);
  const randomSpecialCharIndex = generateRandomIndex(passwordLength, [randomLetterIndex, randomNumberIndex]);

  const passwordWithRandomLetter = replaceAt(randomPassword, randomLetterIndex, randomLetter);
  const passwordWithRandomNumber = replaceAt(passwordWithRandomLetter, randomNumberIndex, randomNumber);
  const finalPassword = replaceAt(passwordWithRandomNumber, randomSpecialCharIndex, randomSpecialChar);

  return finalPassword;
};

export default generateRandomPassword;
